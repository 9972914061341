import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ElementProps, Menu, Space } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import api from "../../api";
import { Organization } from "../../apiQueries/users";
import { appHref } from "../../app/AppRouter";
import useErrorToast from "../../state/useErrorToast";
import useUser from "../../state/useUser";

const Label = (props: ElementProps<typeof Menu.Label>) => (
  <Menu.Label p="xs" fz="xs" fw="bold" c="black" {...props} />
);
const commonItemProps = { p: "xs", c: "gray.8", fw: "normal" };
const Item = (props: ElementProps<typeof Menu.Item<"div">>) => {
  return <Menu.Item component="div" {...commonItemProps} {...props} />;
};
const LinkItem = (props: ElementProps<typeof Menu.Item<"a">>) => {
  return <Menu.Item component="a" {...commonItemProps} {...props} />;
};
const Divider = () => <Menu.Divider />;

export type OrgDropdownMenuProps = { forceOpen?: boolean };
export default function OrgDropdownMenu({
  forceOpen,
}: OrgDropdownMenuProps): React.JSX.Element {
  const { user, setUser } = useUser();
  const { showErrorToast } = useErrorToast();

  const { mutate: updateActiveOrganization } = useMutation({
    mutationFn: api.postActiveOrganization,
    onSuccess: (newUserData) => {
      setUser(newUserData);
    },
    onError: showErrorToast,
  });
  return (
    <Menu.Dropdown w="250">
      <Label>{user?.activeOrganization?.name}</Label>
      {user?.availableOrganizations.length > 1 && (
        <>
          <Menu shadow="sm" position="right" withArrow opened={forceOpen}>
            <Menu.Target>
              <div>
                <Item rightSection={<FontAwesomeIcon icon={faChevronRight} />}>
                  Switch Organization
                </Item>
              </div>
            </Menu.Target>
            <Menu.Dropdown w="250">
              {user.availableOrganizations.map((org: Organization) => (
                <Item
                  onClick={() =>
                    updateActiveOrganization({ organizationId: org.id })
                  }
                  key={org.id}
                >
                  {org.name}
                </Item>
              ))}
            </Menu.Dropdown>
          </Menu>
          <Divider />
        </>
      )}
      <LinkItem href={appHref("/manage-org/aws-connection")}>
        Org Settings
      </LinkItem>
      <Divider />
      <LinkItem href={appHref("/manage-org/members")}>Invites</LinkItem>
      <Space h="48" />
      <Label>{user?.name}</Label>
      <LinkItem href={appHref("/logout")}>Logout</LinkItem>
    </Menu.Dropdown>
  );
}
