import api from "../../api";
import ErrorScreen from "../../components/ErrorScreen";
import Hr from "../../components/uikit/Hr";
import Indicator from "../../components/uikit/Indicator.tsx";
import PageHeading from "../../components/uikit/PageHeading.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";

import LayoutV2 from "../../components/LayoutV2/LayoutV2";
export default function OrgBillingPage() {
  const { fetchedState, loading, error, fetched } = useAsyncFetch(
    api.getOrganization,
  );

  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack className="page-content" col gap={5}>
            <PageHeading>Billing</PageHeading>
            <Hr />
            <p className="subtitle">
              Plan Type:
              <Indicator
                name={fetchedState.subscription.typeName.toLowerCase()}
                className="ml-2"
              />
            </p>
            <p>
              Skyway is currently in Beta. Thanks for letting us know what you
              think!
            </p>
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}
