import {
  faBucket,
  faCloudWord,
  faDollarSign,
  faMoneyCheckDollar,
  faRightLeft,
} from "../../components/icons.jsx";

export default function categoryIcon(category, secondary) {
  const icons = secondary ? secondaryIcons : primaryIcons;
  return icons[category] || icons.default;
}

const primaryIcons = {
  spend: faMoneyCheckDollar,
  s3: faBucket,
  "data-transfer": faRightLeft,
  default: faCloudWord,
};

const secondaryIcons = { ...primaryIcons, spend: faDollarSign };
