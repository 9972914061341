export const formatUSD = (number: number) => {
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

const units = ["GB", "TB", "PB", "EB", "ZB", "YB"];
export const unitFromReductionFactor = (reductionFactor: number) => {
  // Given a reductionFactor of, eg, 1,048,576 (2^20), return "PB"
  const index = Math.log2(reductionFactor) / 10;
  return units[index];
};

// Convert a number to a human-readable string (eg 3k)
export const abbreviateNumber = (number: number, precision = 3): string => {
  if (number < 1e3) return number.toPrecision(precision);
  const abbreviations = ["k", "m", "b", "t"];
  const exp = Math.floor(Math.log(number) / Math.log(1e3));
  const abbreviation = abbreviations[exp - 1];
  if (abbreviation === undefined) {
    console.warn("Number too large to abbreviate:", number);
    return number.toPrecision(precision);
  }
  const value = number / Math.pow(1e3, exp);
  return value.toPrecision(3) + abbreviation;
};
