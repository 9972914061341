import React from "react";
import useUser from "./useUser.jsx";

export default function useRoleAccess(
  { forceReadAll } = { forceReadAll: false },
) {
  const { user } = useUser();
  const orgRoleAccess = user?.activeOrganization?.roleAccess;
  const userRoleAccess = user?.userRoleAccess;
  const canAccess = React.useCallback(
    (name: string, access: "read" | "write") => {
      if (!orgRoleAccess && !userRoleAccess) {
        return false;
      }
      return (
        orgRoleAccess?.[name]?.includes(access) ||
        userRoleAccess?.[name]?.includes?.(access)
      );
    },
    [orgRoleAccess, userRoleAccess],
  );
  const canRead = React.useCallback(
    (name: string) => {
      return forceReadAll || canAccess(name, "read");
    },
    [forceReadAll, canAccess],
  );
  const canWrite = React.useCallback(
    (name: string) => canAccess(name, "write"),
    [canAccess],
  );
  const cannotWrite = React.useCallback(
    (name: string) => !canAccess(name, "write"),
    [canAccess],
  );

  const result = React.useMemo(
    () => ({ canAccess, canRead, canWrite, cannotWrite }),
    [canAccess, canRead, canWrite, cannotWrite],
  );
  return result;
}
