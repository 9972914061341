import "./GoBackBreadcrumb.css";
import { faArrowLeft } from "../icons.jsx";
import Button from "./Button";

export default function GoBackBreadcumb({ to, children }) {
  return (
    <div className="go-back-breadcrumb">
      <Button
        size="sm"
        href={to}
        leftArrow={faArrowLeft}
        variant="hollow"
        className="go-back-breadcrumb-button"
      >
        {children}
      </Button>
    </div>
  );
}
