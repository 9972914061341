import isUndefined from "lodash/isUndefined";
import clsx from "clsx";

/**
 * Flex div with a gap.
 *
 * direction='vertical', direction='column', col={true} and column={true} all get a flex-direction: column.
 *
 * direction='horizontal', direction='row', row={true} all get a flex-direction: row.
 *
 * Defaults to flex-direction: column since that is most popular.
 */
type StackProps = {
  direction?: "vertical" | "horizontal" | "row" | "column";
  row?: boolean;
  col?: boolean;
  column?: boolean;
  gap?: number;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;
export default function Stack({
  direction,
  row,
  col,
  column,
  gap,
  className,
  ...rest
}: StackProps) {
  gap = isUndefined(gap) ? 0 : gap;
  let cls = "column";
  if (row || direction === "horizontal" || direction === "row") {
    cls = "row";
  } else if (col || column) {
    cls = "column";
  }
  return (
    <div className={clsx("flex", cls, `gap-${gap}`, className)} {...rest} />
  );
}
