import config from "../app/config";
import Promise from "bluebird";

(Promise as any).delayOr = function delayOr<T>(
  durationMs: number,
  otherPromise: Promise<T>,
  options?: { buffer: number },
) {
  const started = Date.now();
  return otherPromise.then((r) => {
    options = options || { buffer: 100 };
    const waited = Date.now() - started;
    const stillLeftToWait = durationMs - waited;
    // If we have a number of milliseconds or less than buffer left to wait,
    // we can return the original result without delay, because we know we took about durationMs.
    if (stillLeftToWait <= options.buffer) {
      return r;
    }
    // Otherwise, we should delay until the intended elapsed time has been reached.
    return Promise.delay(stillLeftToWait, r);
  });
};

(Promise.prototype as any).delayOr = function delayOr(
  durationMs: number,
  options: { buffer: number },
) {
  return (Promise as any).delayOr(durationMs, this, options);
};

(Promise.prototype as any).tapTap = function tapTap(f: Function) {
  return this.tap(f).tapCatch(f);
};

Promise.config({
  cancellation: true,
  longStackTraces: config.debug,
  warnings: config.debug,
});

export default Promise;
