import "./GanttChart.css";
import dayjs from "../../../modules/dayConfig";
import range from "lodash/range";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlexiLink } from "../links";
import HoverableTooltipTrigger from "../HoverableTooltipTrigger.jsx";
import Tooltip from "../Tooltip.jsx";
import Markdown from "../Markdown.jsx";

/**
 *
 * @param {dayjs.Dayjs} today If given, show a 'today' indicator.
 * @param {dayjs.Dayjs} start
 * @param {number} monthsForward
 * @param {Array<GanttItem>} items
 */
export default function GanttChart({ today, start, monthsForward, items }) {
  if (start.date() !== 1) {
    console.error(`GanttChart must start on day (date) 1, got ${start.date()}`);
    return <div>error</div>;
  }
  const monthNames = dayjs.monthsShort();
  let headings = [];
  let totalDaysInPeriod = 0;
  range(0, monthsForward).map((i) => {
    const dayjsInMonth = start.add(i, "months");
    const daysInMonth = dayjsInMonth.daysInMonth();
    totalDaysInPeriod += daysInMonth;
    headings.push({ name: monthNames[dayjsInMonth.month()], daysInMonth });
  });
  const end = start.add(totalDaysInPeriod, "days");
  let todayOffset;
  if (today) {
    const daysFromStartToToday = today.diff(start, "days");
    todayOffset = daysFromStartToToday / totalDaysInPeriod;
    todayOffset = todayOffset * 100;
  }
  return (
    <div className="gantt-root">
      <div className="gantt-heading">
        {headings.map(({ name, daysInMonth }, i) => (
          <div
            key={`${name}-${i}`}
            className="gantt-header text-desc text-center"
            style={{ width: `${(daysInMonth / totalDaysInPeriod) * 100}%` }}
          >
            {name}
          </div>
        ))}
      </div>
      <div className="gantt-content">
        {items.map((item, i) => {
          let { start: itemStart, end: itemEnd } = item;
          if (!itemStart.isValid()) {
            itemStart = start.subtract(1, "day");
          }
          if (!itemEnd.isValid()) {
            itemEnd = end.add(1, "day");
          }
          // If true, the bar has a hard edge on the left.
          const startsBeforeStart = itemStart.isBefore(start);
          // If true, bar has a hard edge on the right
          const endsAfterEnd = itemEnd.isAfter(end);

          // Do the left offset and width math with numbers
          // in a consistent range between items and the graph area.
          const effectiveStart = dayjs.max(start, itemStart);
          const effectiveEnd = dayjs.min(end, itemEnd);

          const startsDaysAfterStart = effectiveStart.diff(start, "days");
          const endsDaysAfterStart = effectiveEnd.diff(start, "days");

          // The leading indicator line has this width.
          const barStart = (startsDaysAfterStart / totalDaysInPeriod) * 100;
          // The bar 'ends' at this width, so we need to subtract the start.
          const barWidth =
            (endsDaysAfterStart / totalDaysInPeriod) * 100 - barStart;

          const linkify = (c) =>
            item.href ? (
              <FlexiLink href={item.href} className="text-decoration-none">
                {c}
              </FlexiLink>
            ) : (
              c
            );

          return (
            <div key={item.key} className="gantt-line">
              <div
                className="gantt-line-indicator"
                style={{ width: `${barStart}%` }}
              />
              <div
                className={clsx(
                  "gantt-bar",
                  startsBeforeStart && "gantt-bar-start-early",
                  endsAfterEnd && "gantt-bar-end-late",
                )}
                style={{
                  backgroundColor: `var(--color-${COLORS[i % COLORS.length]})`,
                  width: `${barWidth}%`,
                }}
              >
                <div className="flex align-center">
                  {linkify(
                    <FontAwesomeIcon
                      className="gantt-bar-icon"
                      icon={item.icon}
                    />,
                  )}
                  <div className="gantt-bar-title subtitle">
                    {linkify(item.title)}
                  </div>
                </div>
                {item.markers?.map(({ key, at, title, icon }) => {
                  if (at.isBefore(start) || at.isAfter(end)) {
                    return null;
                  }
                  const offsetFromStart =
                    at.diff(start, "days") / totalDaysInPeriod;
                  return (
                    <HoverableTooltipTrigger key={key}>
                      <div
                        className="gantt-bar-marker"
                        style={{ left: `${offsetFromStart * 100}%` }}
                      >
                        {linkify(
                          <FontAwesomeIcon
                            className="gantt-bar-marker-icon"
                            icon={icon}
                          />,
                        )}
                      </div>
                      <Tooltip style={{ maxWidth: 230 }}>
                        <Markdown>{title}</Markdown>
                      </Tooltip>
                    </HoverableTooltipTrigger>
                  );
                })}
                <div className="gantt-bar-suffix text-desc">
                  {linkify(item.suffix)}
                </div>
              </div>

              <div
                key={item.key}
                className="gantt-line-indicator"
                style={{ flex: 1 }}
              />
            </div>
          );
        })}
        {todayOffset && (
          <div className="gantt-today" style={{ left: `${todayOffset}%` }} />
        )}
      </div>
    </div>
  );
}

const COLORS = ["red", "orange", "green", "grey"];

/**
 * @typedef GanttItem
 * @property {*} key
 * @property {dayjs.Dayjs} start
 * @property {dayjs.Dayjs} end
 * @property {Icon} icon
 * @property {string} title
 * @property {string} suffix
 * @property {string=} href
 * @property {GanttMarker[]=} markers
 */

/**
 * @typedef GanttMarker
 * @property {dayjs.Dayjs} at
 * @property {string} title
 * @property {Icon} icon
 */
