import dayjs from "dayjs";
import get from "lodash/get";
import last from "lodash/last";
import api from "../../api";
import ErrorComponent from "../../components/ErrorComponent.jsx";
import ErrorScreen from "../../components/ErrorScreen";
import RelatedList from "../../components/RelatedList.jsx";
import ResourceRowList from "../../components/ResourceRowList.jsx";
import { faCalendarCheck, faCalendarTimes } from "../../components/icons.jsx";
import Card from "../../components/uikit/Card";
import ComponentLoader from "../../components/uikit/ComponentLoader.jsx";
import GoBackBreadcumb from "../../components/uikit/GoBackBreadcrumb.jsx";
import Hr from "../../components/uikit/Hr";
import Indicator from "../../components/uikit/Indicator";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import StatCard from "../../components/uikit/StatCard";
import StatCardRow from "../../components/uikit/StatCardRow.jsx";
import BulletBarChart from "../../components/uikit/charts/BulletBarChart.tsx";
import { unitFromReductionFactor } from "../../modules/formatters";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";
import CommitmentCheckinsChart from "./CommitmentCheckinsChart.jsx";
import CommitmentItem from "./CommitmentItem.jsx";
import ContractItem from "./ContractItem.jsx";
import categoryIcon from "./categoryIcon.js";

import LayoutV2 from "../../components/LayoutV2/LayoutV2";
export default function CommitmentDetailPage({ commitmentType, commitmentId }) {
  const { fetchedState, loading, error, fetched } = useAsyncFetch(
    api.getContractCommitment,
    {
      fetchArgs: { type: commitmentType, id: commitmentId },
      handleError: true,
    },
  );
  const {
    fetchedState: relatedState,
    loading: relatedLoading,
    error: relatedError,
  } = useAsyncFetch(api.getCommitmentsRelatedToCommitment, {
    fetchArgs: { id: commitmentId, type: commitmentType },
    handleError: true,
  });

  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen error={error} />}
      {fetched && (
        <>
          <Stack gap={4} className="page-content">
            <Stack row className="justify-between align-center">
              <Stack row gap={3} className="align-center">
                <h2>{fetchedState.name}</h2>
                <div>
                  <Indicator name={fetchedState.contract.activity.name} />
                </div>
              </Stack>
              <GoBackBreadcumb to="/contracts/commitments">
                Back to Commitments
              </GoBackBreadcumb>
            </Stack>
            <StatCardRow>
              <StatCard
                color="red"
                icon={categoryIcon(fetchedState.category, true)}
                title="Commitment"
                subtitle={last(fetchedState.checkins).formattedAmount}
              />
              <StatCard
                color="grey"
                icon={faCalendarCheck}
                title="Start Date"
                subtitle={dayjs(fetchedState.contract.period.lower).format(
                  "ll",
                )}
              />
              <StatCard
                color="grey"
                icon={faCalendarTimes}
                title="End Date"
                subtitle={dayjs(fetchedState.contract.period.upper).format(
                  "ll",
                )}
              />
            </StatCardRow>
            <Card className="p-3">
              <CommitmentCheckinsChart
                currentCheckin={fetchedState.currentCheckin}
                checkins={fetchedState.checkins}
              />
            </Card>
            <Card className="p-3">
              <HistoryChart
                id={commitmentId}
                type={commitmentType}
                unit={fetchedState.unit}
              />
            </Card>
            {relatedState.contract && (
              <>
                <Hr className="my-2" />
                <RelatedList
                  title="Contracts"
                  subtitle="This commitment is part of the contract below"
                  viewAllText="View All Contracts"
                  viewAllHref={get(relatedState, "contract.detailUrl")}
                  loading={relatedLoading}
                  error={relatedError}
                >
                  <ContractItem {...relatedState.contract} />
                </RelatedList>
              </>
            )}
            {relatedState.commitments && (
              <>
                <Hr className="my-2" />
                <RelatedList
                  title="Related Commitments"
                  subtitle="Other commitments on the same contract as this one"
                  viewAllText="View All Commitments"
                  viewAllHref="/contracts/commitments"
                  loading={relatedLoading}
                  error={relatedError}
                >
                  <ResourceRowList>
                    {(relatedState.commitments || []).map((c) => (
                      <CommitmentItem key={c.detailUrl} {...c} />
                    ))}
                  </ResourceRowList>
                </RelatedList>
              </>
            )}
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function HistoryChart({ id, type, unit }) {
  const { loading, error, fetched, fetchedState } = useAsyncFetch(
    api.getContractCommitmentHistoryChart,
    { fetchArgs: { id, type } },
  );
  const unitLabel = unit?.replace(
    "GB",
    unitFromReductionFactor(fetchedState.reductionFactor),
  );
  const title =
    type === "contract_spend_commitments"
      ? "Commitment Spend ($)"
      : `Commitment Usage (${unitLabel})`;
  return (
    <Stack>
      <h3 className="mb-4">{title}</h3>
      {loading && <ComponentLoader height={200} />}
      {error && <ErrorComponent style={{ height: 200 }} />}
      {fetched && (
        <BulletBarChart
          height={200}
          width="100%"
          barWidth={20}
          yAxis={fetchedState.yAxis}
          items={fetchedState.items.map((item) => ({ ...item }))}
          unit={unitLabel}
        />
      )}
    </Stack>
  );
}
