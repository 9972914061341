import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import ErrorScreen from "../../components/ErrorScreen";
import {
  faBoxArchive,
  faCalendarCheck,
  faCalendarTimes,
  faDownloadSolid,
  faSitemap,
  faTag,
} from "../../components/icons.jsx";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import RelatedList from "../../components/RelatedList.jsx";
import ResourceRowList from "../../components/ResourceRowList.jsx";
import Button from "../../components/uikit/Button";
import Card, { CardBody } from "../../components/uikit/Card";
import GoBackBreadcumb from "../../components/uikit/GoBackBreadcrumb.jsx";
import Hr from "../../components/uikit/Hr";
import Indicator from "../../components/uikit/Indicator";
import { RelLink } from "../../components/uikit/links";
import Markdown from "../../components/uikit/Markdown.jsx";
import RadioGroup from "../../components/uikit/RadioGroup.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import StatCard from "../../components/uikit/StatCard";
import StatCardRow from "../../components/uikit/StatCardRow.jsx";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";
import useClientsideSearchParams from "../../state/useClientsideSearchParams.jsx";
import CommitmentItem from "./CommitmentItem.jsx";
import { indicatorPeriodDetails } from "./contractFuncs.js";
import ContractProgressGraph from "./ContractProgressGraph.jsx";

export default function ContractDetailPage() {
  const params = useParams();
  const contractId = Number(params.id);
  const { fetchedState, loading, error, fetched } = useAsyncFetch(
    api.getContract,
    { fetchArgs: { id: contractId }, handleError: true },
  );
  let content = null;
  if (fetchedState.isImporting) {
    content = <Importing />;
  } else if (fetchedState.isPrimary) {
    content = <PrimaryContract contract={fetchedState} />;
  } else if (fetched) {
    content = <Contract contract={fetchedState} />;
  }
  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen error={error} />}
      {fetched && (
        <>
          <Stack gap={4} className="page-content">
            <Stack row className="justify-between align-center">
              <Stack row gap={3} className="align-center">
                <h2>{fetchedState.name}</h2>
                <Indicator
                  name={indicatorPeriodDetails(fetchedState).indicator}
                />
              </Stack>
              <GoBackBreadcumb to="/contracts/list">
                Back to Contracts
              </GoBackBreadcumb>
            </Stack>
            {content}
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function Importing() {
  return (
    <p>
      Contract is being imported by Skyway. We&rsquo;ll reach out when
      it&rsquo;s complete or if we have run into any issues.
    </p>
  );
}

function Contract({ contract }) {
  const {
    period,
    standardRenewalEvent,
    executedAt,
    supercededBy,
    contractType,
  } = contract;
  const {
    fetchedState: relatedState,
    loading: relatedLoading,
    error: relatedError,
  } = useAsyncFetch(api.getCommitmentsRelatedToContract, {
    fetchArgs: { id: contract.id },
    handleError: true,
  });

  const { indicator, color } = indicatorPeriodDetails({ period });
  return (
    <>
      <StatCardRow>
        {supercededBy && (
          <StatCard
            icon={faBoxArchive}
            title="Superceded"
            subtitle={
              <RelLink href={`/contracts/${supercededBy.id}`}>
                {supercededBy.name}
              </RelLink>
            }
            color="red"
          />
        )}
        <StatCard
          icon={faSitemap}
          title="Contract Type"
          subtitle={contractType}
          color="grey"
        />
        <StatCard
          icon={faTag}
          title="Executed"
          subtitle={dayjs(executedAt).format("L")}
          color="grey"
        />
        <StatCard
          icon={faCalendarCheck}
          title="Start Date"
          subtitle={dayjs(period.lower).format("ll")}
          color="grey"
        />
        <StatCard
          icon={faCalendarTimes}
          title="End Date"
          subtitle={dayjs(period.upper).format("ll")}
          color="grey"
        />
      </StatCardRow>
      <Card>
        <CardBody>
          <ContractProgressGraph
            indicator={indicator}
            color={color}
            standardRenewalEvent={standardRenewalEvent}
            period={period}
          />
        </CardBody>
      </Card>
      <Hr />
      <RelatedList
        title="Related Commitments"
        subtitle="Other commitments on this contract"
        viewAllText="View All Commitments"
        viewAllHref="/contracts/commitments"
        loading={relatedLoading}
        error={relatedError}
      >
        <ResourceRowList>
          {(relatedState.items || []).map((c) => (
            <CommitmentItem key={c.detailUrl} {...c} />
          ))}
        </ResourceRowList>
      </RelatedList>
      <Hr />
      <Stack row className="justify-between align-center">
        <h5>Contract Language</h5>
        {contract.uploadedFiles.map((uf) => (
          <Stack key={uf.presignedGetUrl} className="align-start">
            <div>
              <Button
                variant="primary"
                rightArrow={faDownloadSolid}
                className="mb-3"
                href={uf.presignedGetUrl}
              >
                Download PDF
              </Button>
            </div>
          </Stack>
        ))}
      </Stack>
      <ContractLanguage {...contract} />
    </>
  );
}

function PrimaryContract({ contract }) {
  const { executedAt, supercededBy, contractType } = contract;
  return (
    <>
      <StatCardRow>
        {supercededBy && (
          <StatCard
            icon={faBoxArchive}
            title="Superceded"
            subtitle={
              <RelLink href={`/contracts/${supercededBy.id}`}>
                {supercededBy.name}
              </RelLink>
            }
            color="red"
          />
        )}
        <StatCard
          icon={faSitemap}
          title="Contract Type"
          subtitle={contractType}
          color="grey"
        />
        <StatCard
          icon={faTag}
          title="Executed"
          subtitle={dayjs(executedAt).format("L")}
          color="grey"
        />
      </StatCardRow>
      {contract.uploadedFiles.map((uf) => (
        <Stack key={uf.presignedGetUrl} className="align-start">
          <div>
            <Button
              variant="primary"
              rightArrow={faDownloadSolid}
              className="mb-3"
              href={uf.presignedGetUrl}
            >
              Download PDF
            </Button>
          </div>
        </Stack>
      ))}
    </>
  );
}

function ContractLanguage({ structuredSections }) {
  const terms = structuredSections.map(({ term }) => term);
  const { searchParams, setSearchParam } = useClientsideSearchParams();
  let activeTerm = searchParams.get("term") || terms[0];
  let activeSection = structuredSections.find((sec) => sec.term === activeTerm);
  if (!activeSection) {
    // the term from the URL is invalid
    activeTerm = terms[0];
    activeSection = structuredSections.find((sec) => sec.term === activeTerm);
  }
  return (
    <Stack row gap={4}>
      <RadioGroup
        label="Contract Sections"
        labelHidden
        items={terms.map((t) => ({ label: t, value: t }))}
        value={activeTerm}
        style={{ maxWidth: 200 }}
        onChange={(t) => setSearchParam("term", t)}
      ></RadioGroup>
      <Card className="flex-1">
        <div className="p-5">
          <h6 className="h7 color-blurple mb-4">{activeTerm}</h6>
          {activeSection.meaningMd ? (
            <Markdown>{activeSection.meaningMd}</Markdown>
          ) : (
            <ContractLanguageSubsections
              subsections={activeSection.subsections}
            />
          )}
        </div>
      </Card>
    </Stack>
  );
}

function ContractLanguageSubsections({ subsections }) {
  if (isEmpty(subsections)) {
    return null;
  }
  return subsections.map(({ title, explainationMd, items }) => (
    <React.Fragment key={title}>
      <Hr my={4} />
      {title && (
        <p className="text-btn-sm">
          <strong>{title}</strong>
        </p>
      )}
      {explainationMd && (
        <Markdown
          className="mt-2"
          components={{
            p: (props) => <p {...props} className="text-desc color-grey" />,
          }}
        >
          {explainationMd}
        </Markdown>
      )}
      {!isEmpty(items) && (
        <Stack className="mt-4">
          {items.map(({ name, value }, i) => (
            <React.Fragment key={name + value + i}>
              {i > 0 && <Hr my={3} />}
              <Stack row className="text-btn-sm justify-between">
                {name && <div className="fw-regular">{name}</div>}
                <div>{value}</div>
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      )}
    </React.Fragment>
  ));
}
