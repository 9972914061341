export function indicatorPeriodDetails({ isImporting, period }) {
  let indicator, color;
  if (isImporting) {
    indicator = "importing";
  } else if (period.daysUntilEnd < 0) {
    indicator = "expired";
    color = "red";
  } else if (period.daysUntilStart <= 0) {
    indicator = "active";
    color = "blue";
  } else {
    indicator = "future";
    color = "purple";
  }
  return { indicator, color };
}
