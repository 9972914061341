import { AxiosInstance, AxiosResponse } from "axios";
import config from "../app/config";
import apiBase from "./apiBase";

const instance = apiBase.create(config.apiHost, {
  debug: config.debug,
  chaos: false,
});
const unwrapAxiosResponse = async <T>(
  response: Promise<AxiosResponse<T>>,
): Promise<T> => {
  return (await response).data;
};
export const apiRequest = {
  get: async <t>(...args: Parameters<AxiosInstance["get"]>) => {
    return unwrapAxiosResponse(instance.get<t>(...args));
  },
  post: async <t>(...args: Parameters<AxiosInstance["post"]>) => {
    return unwrapAxiosResponse(instance.post<t>(...args));
  },
  patch: async <t>(...args: Parameters<AxiosInstance["patch"]>) => {
    return unwrapAxiosResponse(instance.patch<t>(...args));
  },
  put: async <t>(...args: Parameters<AxiosInstance["put"]>) => {
    return unwrapAxiosResponse(instance.put<t>(...args));
  },
  delete: async <t>(...args: Parameters<AxiosInstance["delete"]>) => {
    return unwrapAxiosResponse(instance.delete<t>(...args));
  },
};
