import { ComboboxData, Select } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";

export type MonthSelectorProps = {
  availableMonths: string[];
  setMonth: (month: string) => void;
  selectedMonth: string | undefined;
  forceOpen?: boolean;
};

export default function MonthSelector({
  availableMonths,
  setMonth,
  selectedMonth,
  forceOpen,
}: MonthSelectorProps): React.JSX.Element {
  const latestMonth = availableMonths[availableMonths.length - 1];
  // Convert the available months groups by year (and formatted into nice strings)
  const monthGroups = availableMonths.reduceRight(
    (acc: Record<string, string>, month_start) => {
      const month = dayjs(month_start);
      const year = month.year();
      const monthLabel = month.format("MMMM YYYY");
      return {
        ...acc,
        [year]: [
          ...(acc[year] || []),
          { label: monthLabel, value: month_start },
        ],
      };
    },
    {},
  );
  const monthEntries = Object.entries<string[]>(monthGroups);
  const monthData: ComboboxData = monthEntries
    .map(([year, monthItems]) => {
      return { group: year, items: monthItems };
    })
    .reverse();
  return (
    <Select
      data={monthData}
      defaultValue={selectedMonth ?? latestMonth}
      w={300}
      size="xl"
      maxDropdownHeight={500}
      defaultDropdownOpened={forceOpen}
      onChange={(value) => {
        if (value !== null) {
          setMonth(value);
        }
      }}
    />
  );
}
