import React from "react";
import { defaultUndefined } from "../../modules/fp";

/**
 * Trigger that controls isOpen based on mouse over/out events.
 * The same state and callbacks can be shared between multiple elements,
 * so for example multiple elements can control the same tooltip.
 *
 * @param {boolean=} defaultOpen
 * @param {number=} delay Default to 0.
 * @param {number=} closeDelay Default to 500.
 * @param {boolean=} disabled If true, short-circuit all functionality to improve performance.
 * @return {{isOpen: boolean, setIsOpen, hoverableProps: object}}
 */

export default function useHoverableTrigger({
  defaultOpen,
  delay,
  closeDelay,
  disabled,
} = {}) {
  delay = defaultUndefined(delay, 300);
  closeDelay = defaultUndefined(closeDelay, 500);
  const [mouseEnter, setMouseEnter] = React.useState(false);
  const [mouseExit, setMouseExit] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(defaultOpen || false);

  React.useEffect(() => {
    if (disabled) {
      return;
    }
    if (mouseEnter && !mouseExit) {
      const h = window.setTimeout(() => setIsOpen(true), delay);
      return () => window.clearTimeout(h);
    } else if (mouseExit && !mouseEnter) {
      const h = window.setTimeout(() => setIsOpen(false), closeDelay);
      return () => window.clearTimeout(h);
    }
  }, [closeDelay, delay, disabled, mouseEnter, mouseExit]);

  const hoverableProps = React.useMemo(() => {
    if (disabled) {
      return {};
    }
    return {
      onMouseOver: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setMouseEnter(true);
        setMouseExit(false);
      },
      onMouseOut: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setMouseEnter(false);
        setMouseExit(true);
      },
    };
  }, [disabled]);

  return { hoverableProps, isOpen, setIsOpen };
}
