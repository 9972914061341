import SimpleBulletChart from "../../components/uikit/charts/SimpleBulletChart.jsx";
import { faArrowsRotate } from "../../components/icons.jsx";
import Stack from "../../components/uikit/Stack";
import dayjs from "dayjs";

export default function ContractProgressGraph({
  period,
  indicator,
  color,
  standardRenewalEvent,
}) {
  let timingText;
  let timingTextDays;
  if (indicator === "future") {
    timingText = "Starts in";
    timingTextDays = `${period.daysUntilStart} days`;
  } else if (indicator === "expired") {
    timingText = "Ended";
    timingTextDays = `${Math.abs(period.daysUntilEnd)} days ago`;
  } else {
    timingText = "Ends in";
    timingTextDays = `${period.daysUntilEnd} days`;
  }

  return (
    <div className="contract-progress-graph">
      <p className="text-right">
        {timingText} <strong>{timingTextDays}</strong>
      </p>
      <SimpleBulletChart
        shape="linear"
        complete={period.elapsed * 100}
        color={color}
        vertical={
          standardRenewalEvent.relevant && {
            at: standardRenewalEvent.contractPeriodElapsedPercentage * 100,
            icon: faArrowsRotate,
          }
        }
      />
      <Stack row className="justify-between text-desc color-grey">
        <div>Starts: {dayjs(period.lower).format("L")}</div>
        <div>{dayjs(period.upper).format("L")}</div>
      </Stack>
    </div>
  );
}
