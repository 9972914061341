import Stack from "./Stack";
import React from "react";

export default function StatCardRow({ children }) {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }
    return React.cloneElement(child, {
      className: "flex-1 nowrap",
      contained: true,
      style: { minWidth: 220 },
    });
  });
  return (
    <Stack row gap={3} className="flex-wrap">
      {childrenWithProps}
    </Stack>
  );
}
