import skywayLogo from "../assets/images/skyway_logo.svg";
import Layout from "../components/Layout";
import Card, { CardBody } from "../components/uikit/Card";
import { RelLink } from "../components/uikit/links";
import Stack from "../components/uikit/Stack";

export default function AccountCreatedPage() {
  return (
    <Layout noNav>
      <Stack className="align-center">
        <RelLink href="/">
          <img src={skywayLogo} alt="logo" width={150} className="my-5" />
        </RelLink>
        <Card className="bg-light-grey" style={{ width: 500 }}>
          <CardBody className="p-5 text-center">
            <h1 className="mb-4">Check your email</h1>
            <p className="subtitle">
              Great, we sent you an email. Follow the link to finish setting up
              your account.
            </p>
          </CardBody>
        </Card>
      </Stack>
    </Layout>
  );
}
