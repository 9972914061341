import useQuery from "../state/useQuery.jsx";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import useUser from "../state/useUser.jsx";
import AuthLayout from "./auth/AuthLayout.jsx";
import TextField from "../components/uikit/TextField.jsx";
import useFormSubmitter from "../state/useFormSubmitter.js";

export default function ResetPasswordPage() {
  const [state, setState] = useState({});
  const query = useQuery();
  const { prepareSubmit, handleSubmitError } = useFormSubmitter();
  const navigate = useNavigate();
  const { setUser } = useUser();

  function handleSubmit(e) {
    prepareSubmit(e);
    api
      .resetPassword({ ...state, token: query.get("token") || "" })
      .then((r) => {
        setUser(r.data);
        navigate("/dashboard");
      })
      .catch(handleSubmitError);
  }

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  return (
    <AuthLayout
      heading="Reset your Password"
      formButtonProps={{ submit: "Change Password" }}
      footerLink={<Link to="/">Back to signin.</Link>}
      onSubmit={handleSubmit}
    >
      <TextField
        name="password"
        label="Password"
        type="password"
        placeholder="Enter password"
        autoComplete="new-password"
        wide
        onChange={handleChange}
      />
    </AuthLayout>
  );
}
