import clsx from "clsx";
import "./ConsumptionProgressPill.css";
import dayjs from "../../../modules/dayConfig";
import Tooltip from "../Tooltip.jsx";
import HoverableElement from "../HoverableElement.jsx";

/**
 * @param {number|string=} width
 * @param {ConsumptionProgress} progress
 * @param {TimeRange} period
 * @param {*} tooltip
 * @param {object=} tooltipProps
 * @param {string=} className
 * @param {object=} style
 */
export default function ConsumptionProgressPill({
  width,
  progress,
  period,
  tooltip,
  tooltipProps,
  className,
  style,
}) {
  width = width || "100%";
  const rootSty = { width, ...style };
  const barFillStyle = {};
  const barEmptyStyle = {};
  let color, textCls;
  if (period.future) {
    color = "grey";
    textCls = "color-grey";
    barFillStyle.width = "100%";
    rootSty.opacity = "0.5";
  } else if (period.past) {
    color = trackingColors[progress.tracking];
    barFillStyle.width = `${progress.valueRatio * 100}%`;
    barEmptyStyle.width = `${(1 - progress.valueRatio) * 100}%`;
  } else {
    color = "blue";
    barFillStyle.width = `${progress.valueRatio * 100}%`;
    barEmptyStyle.width = `${(1 - progress.valueRatio) * 100}%`;
  }
  return (
    <div
      className={clsx("consumption-progress-pill", className)}
      style={rootSty}
    >
      <HoverableElement
        className="bar"
        style={{ "--color": `var(--color-${color})` }}
        tooltip={
          tooltip && (
            <Tooltip placement="top" {...tooltipProps}>
              {tooltip}
            </Tooltip>
          )
        }
      >
        <div className="bar-fill" style={barFillStyle} />
        <div className="bar-empty" style={barEmptyStyle} />
      </HoverableElement>
      <div className={clsx("title", textCls)}>{progress.maxValueFormatted}</div>
      <div className={clsx("subtitle", textCls)}>
        {dayjs(period.upper).format("MM/YYYY")}
      </div>
    </div>
  );
}

const trackingColors = {
  under_target: "red",
  over_target: "green",
};
