import clsx from "clsx";
import Stack from "../Stack";
import SimpleBulletChart from "./SimpleBulletChart.jsx";
import "./ConsumptionTimelineChart.css";
import dayjs from "dayjs";

/**
 * @param {ConsumptionProgress} progress
 * @param {TimeRange} period
 * @param {string=} className
 */
export default function ConsumptionTimelineChart({
  className,
  progress,
  period,
}) {
  const colorKey = progressColorKey(progress);
  return (
    <div className={clsx("consumption-timeline-chart", className)}>
      <Stack row className="justify-between">
        <div className={clsx("fw-bold", textClasses[colorKey])}>
          {progress.currentValueFormatted}
        </div>
        <div>{progress.maxValueFormatted}</div>
      </Stack>
      <SimpleBulletChart
        shape="linear"
        complete={progress.valueRatio * 100}
        color={bulletThemes[colorKey]}
      />
      <SimpleBulletChart shape="linear" complete={period.elapsed * 100} />
      <Stack row className="justify-between text-desc color-grey">
        <div>{dayjs(period.lower).format("L")}</div>
        <div>{dayjs(period.upper).format("L")}</div>
      </Stack>
    </div>
  );
}

/**
 * @param progress {ConsumptionProgress}
 * @return {string}
 */
function progressColorKey(progress) {
  if (progress.currentValue === 0 && progress.tracking !== "under_target") {
    return "default";
  }
  return progress.tracking;
}

const bulletThemes = {
  under_target: "red",
  on_target: "green",
  over_target: "purple",
  default: null,
};

const textClasses = {
  under_target: "color-red",
  on_target: "color-green",
  over_target: "color-purple",
  default: "color-grey",
};
