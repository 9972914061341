import HoverableTooltipTrigger from "./HoverableTooltipTrigger.jsx";

export default function HoverableElement({ as, tooltip, ...rest }) {
  const C = as || "div";
  if (!tooltip) {
    return <C {...rest} />;
  }
  return (
    <HoverableTooltipTrigger>
      <C {...rest} />
      {tooltip}
    </HoverableTooltipTrigger>
  );
}
