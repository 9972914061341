import Dropdown from "./Dropdown.jsx";
import "./InlineSelectField.css";

/**
 * Display the label inside the dropdown.
 * NOTE: this component must be controlled (pass in the 'value' prop).
 *
 * @param {Array<DropdownItemProps>} items
 * @param {string} label

 * @param {function} onChange
 * @param {InputFieldProps} props
 */
export default function InlineSelectField({
  label,
  items,
  onChange,
  ...props
}) {
  function renderSelectValue({ selectedText, isPlaceholder }) {
    if (isPlaceholder) {
      return label;
    }
    return (
      <span>
        <strong>{label}:</strong> {selectedText}
      </span>
    );
  }
  return (
    <Dropdown
      items={items}
      buttonProps={{ className: "w-100 text-desc btn-sm" }}
      onSelectionChange={onChange}
      renderSelectValue={renderSelectValue}
      {...props}
    />
  );
}
