import { Tooltip as RATooltip, OverlayArrow } from "react-aria-components";
import "./Tooltip.css";
import clsx from "clsx";

export default function Tooltip({ children, className, placement, ...props }) {
  placement = placement || "top";
  const cls = clsx("tooltip", `tooltip-${placement}`, className);
  const arrow1x = arrowSizes[placement];
  const arrow2x = arrow1x * 2;
  return (
    <RATooltip className={cls} placement={placement} {...props}>
      <OverlayArrow className="tooltip-arrow">
        <svg
          width={arrow2x}
          height={arrow2x}
          viewBox={`0 0 ${arrow2x} ${arrow2x}`}
        >
          <path d={`M0 0 L${arrow1x} ${arrow1x} L${arrow2x} 0`} />
        </svg>
      </OverlayArrow>
      {children}
    </RATooltip>
  );
}

const arrowSizes = {
  top: 16,
  bottom: 16,
  left: 8,
  right: 8,
};
