import { Tooltip } from "@mantine/core";
import clsx from "clsx";
import React from "react";
import { abbreviateNumber } from "../../../modules/formatters";
import "./BulletBarChart.css";
import BulletChart from "./BulletChart";

// Represents a single bar in the chart
type BulletBarChartItem = {
  label: string; // Primary label
  label2?: string; // Optional secondary label
  muted?: boolean; // Whether the bar should be grayed out
  focusMarker?: boolean; // Whether this bar should be focused
  target: number; // Target value
  measurement: number; // Actual measurement value
  actual?: number; // Actual value, potentially reduced (eg converted GB->TB)
  reductionFactor?: number; // Factor to reduce actual value by (eg 1024)
};

// Main component props
type BulletBarChart = {
  items: BulletBarChartItem[]; // Array of bars to display
  yAxis: ChartYAxisItem[]; // Y-axis configuration
  height: number; // Chart height
  width: string; // Chart width (CSS value)
  barWidth: number; // Width of each bar
  unit: string; // Unit to display when needed
};
export default function BulletBarChart({
  items,
  yAxis,
  height,
  width,
  barWidth,
  unit,
}: BulletBarChart) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const barsContainerRef = React.useRef<HTMLDivElement>(null);
  const focusedBarRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (barsContainerRef.current && focusedBarRef.current && rootRef.current) {
      // We cannot use 'scrollIntoView' because we do not want to scroll
      // the browser/ancestors, we only want to scroll the element.
      // This gets the focused bar to the left of the right edge.
      rootRef.current.scroll({
        left:
          focusedBarRef.current.offsetLeft -
          barsContainerRef.current.offsetWidth +
          10,
        behavior: "instant",
      });
    }
  }, []);
  const rootStyle: Record<string, string | number> = { width, height };
  const hasAnyLabel2 = items.some(({ label2 }) => label2);
  if (hasAnyLabel2) {
    rootStyle["--label-vertical-padding"] =
      "calc(var(--label-vertical-padding-base) * 2)";
  }
  return (
    <div ref={rootRef} className="bullet-bar-root" style={rootStyle}>
      <div className="bullet-bar-chartroot">
        <div className="bullet-bar-yaxis">
          {yAxis.map(({ label }, i) => (
            <div key={label} className="bullet-bar-yaxis-group">
              <div className="text-desc bullet-bar-yaxis-text">{label}</div>
              <div
                className={clsx("bullet-bar-yline", i === 0 && "baseline")}
              ></div>
            </div>
          ))}
        </div>
        <div ref={barsContainerRef} className="bullet-bar-bars">
          {items.map(
            ({ label, label2, muted, focusMarker, actual, ...item }, i) => {
              // We want the focused marker, if there is one, to be scrolled into view
              // when the bars overflow. If a bar is focused, scroll it into view via useEffect.
              // However, this would put the focused bar at the very right of the chart,
              // which isn't great, because you don't see the potentially muted future bars.
              // So the previous bar has a focus marker, use the ref on this bar instead,
              // so the focus marker is one bar in from the right.
              let ref: React.RefObject<HTMLDivElement> | undefined = undefined;
              if (focusMarker || (i > 0 && items[i - 1].focusMarker)) {
                ref = focusedBarRef;
              }
              return (
                <div
                  key={label + label2 + i}
                  ref={ref}
                  className="bullet-bar-baritem"
                >
                  <Tooltip
                    label={`${abbreviateNumber(actual ?? 0)} ${unit ?? ""}`}
                    withArrow
                  >
                    <div className="w-100 h-100 flex justify-center">
                      <BulletChart
                        {...item}
                        focusMarker={focusMarker}
                        width={barWidth}
                        height="100%"
                        variant={muted ? "grey" : "blue"}
                      />
                    </div>
                  </Tooltip>
                  <div
                    className="text-desc bullet-bar-label"
                    style={{ maxWidth: barWidth }}
                  >
                    <span>{label}</span>
                    {label2 && <span>{label2}</span>}
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
}
