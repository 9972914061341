import React, { useEffect } from "react";
import ScreenLoader from "../components/uikit/ScreenLoader";
import signOut from "../modules/signOut";
import useErrorToast from "../state/useErrorToast";

export type SignOutPageProps = {};

export default function SignOutPage({}: SignOutPageProps): React.JSX.Element {
  const { showErrorToast } = useErrorToast();
  useEffect(() => {
    signOut()
      .catch(showErrorToast)
      .then(() => {
        window.location.href = "/";
      });
  });
  return <ScreenLoader />;
}
