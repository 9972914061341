import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import FileInput from "../../components/uikit/FileInput.jsx";
import Form from "../../components/uikit/Form.jsx";
import FormButtons from "../../components/uikit/FormButtons.jsx";
import GoBackBreadcumb from "../../components/uikit/GoBackBreadcrumb.jsx";
import InputField from "../../components/uikit/InputField.jsx";
import Stack from "../../components/uikit/Stack";
import TextField from "../../components/uikit/TextField.jsx";
import bluejay from "../../modules/bluejay";
import useFormSubmitter from "../../state/useFormSubmitter.js";

export default function ContractUploadPage() {
  const [name, setName] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const navigate = useNavigate();
  const { prepareSubmit, handleSubmitError } = useFormSubmitter();

  async function handleSubmit(e) {
    prepareSubmit(e);
    try {
      const uploadPromises = files.map(async (f) => {
        const r = await api.uploadFile({
          name: f.name,
          size: f.size,
          type: f.type,
        });
        const { uploadedFileId, presignedUrl, presignedFields } = r.data;
        const postData = new FormData();
        presignedFields.forEach(([k, v]) => {
          postData.append(k, v);
        });
        postData.append("file", f);
        const uploadResp = await fetch(presignedUrl, {
          method: "POST",
          body: postData,
        });
        if (uploadResp.status >= 400) {
          throw new Error(`Invalid request: ${uploadResp.status}`);
        }
        return uploadedFileId;
      });
      const uploadedFileIds = await bluejay.Promise.all(uploadPromises);
      const createResp = await api.createContract({ name, uploadedFileIds });
      navigate(`/contracts/${createResp.data.id}`);
    } catch (e) {
      handleSubmitError(e);
    }
  }

  return (
    <LayoutV2>
      <Stack gap={3} className="page-content" style={{ maxWidth: 600 }}>
        <GoBackBreadcumb to="/contracts/list">Contracts</GoBackBreadcumb>
        <h2>Upload Contract</h2>
        <p>
          After uploading, Skyway will analyze this contract. It may take a
          little while, so we&rsquo;ll let you know when it&rsquo;s done and if
          there are any problems.
        </p>
        <Form onSubmit={handleSubmit} style={{ padding: "24px" }}>
          <TextField
            label="Name"
            type="text"
            required
            value={name}
            wide
            extra="Helpful name to identify this contract more easily."
            onChange={(e) => setName(e.target.value)}
          />
          <InputField
            label="Contract PDF"
            marginTop
            renderInput={() => (
              <div>
                <FileInput
                  files={files}
                  acceptedFileTypes={["application/pdf", ".pdf"]}
                  allowsMultiple
                  onFilesChange={(f) => setFiles(f)}
                />
              </div>
            )}
          ></InputField>
          <FormButtons submit="Upload for Review" />
        </Form>
      </Stack>
    </LayoutV2>
  );
}
