import dayjs from "dayjs";
import "dayjs/locale/en";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(utc);

export default dayjs;
