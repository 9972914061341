import clsx from "clsx";
import dayjs from "dayjs";
import { faContract, faLoader } from "../../components/icons.jsx";
import ResourceRowCard from "../../components/ResourceRowCard";
import Stack from "../../components/uikit/Stack";
import { indicatorPeriodDetails } from "./contractFuncs.js";
import ContractProgressGraph from "./ContractProgressGraph.jsx";

export default function ContractItem({
  detailUrl,
  name,
  executedAt,
  isImporting,
  period,
  standardRenewalEvent,
  asPrimary,
  isFake,
  className,
  ...rest
}) {
  const { indicator, color } = indicatorPeriodDetails({ isImporting, period });
  return (
    <ResourceRowCard
      className={clsx(className, isImporting && "contract-card-importing")}
      icon={isImporting ? faLoader : faContract}
      href={detailUrl}
      indicatorNames={[!isFake && indicator]}
      title={name}
      subtitle={
        !isFake && (
          <Stack row className="text-desc color-grey" gap={3}>
            <p>
              Executed:&nbsp;&nbsp;
              {isImporting ? <>&mdash;</> : dayjs(executedAt).format("L")}
            </p>
          </Stack>
        )
      }
      {...rest}
    >
      {!isImporting && !asPrimary && (
        <ContractProgressGraph
          indicator={indicator}
          color={color}
          period={period}
          standardRenewalEvent={standardRenewalEvent}
        />
      )}
    </ResourceRowCard>
  );
}
