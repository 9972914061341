import { Box, Button, Paper, Stack, Tabs, Title, Select } from "@mantine/core";
import React, { useState } from "react";
import api from "../../../api";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import "./MonthlySummaryPage.css";

type ReportFormat = {
  type: "payer" | "linked" | "payer-private" | "linked-private";
  label: string;
  description: string;
};

const REPORT_FORMATS: ReportFormat[] = [
  {
    type: "payer",
    label: "Payer Account Summary",
    description: "Aggregated view at the payer account level",
  },
  {
    type: "linked",
    label: "Linked Account Details",
    description: "Detailed view showing individual linked accounts",
  },
  {
    type: "payer-private",
    label: "Include private pricing details, payer aggregation",
    description:
      "Aggregated view with detailed private pricing breakdowns at the payer level",
  },
  {
    type: "linked-private",
    label: "Include private pricing details, include linked accounts",
    description:
      "Detailed view with private pricing breakdowns for each linked account",
  },
];

const FILENAME_MAP = {
  payer: "monthly_comparison_payer_summary",
  linked: "monthly_comparison_linked_accounts_detail",
  "payer-private": "monthly_comparison_payer_summary_with_private_pricing",
  "linked-private": "monthly_comparison_linked_accounts_with_private_pricing",
} as const;

const getDownloadUrl = (selectedFormat: ReportFormat["type"]) => {
  const baseUrl = api.axios.getUri() ?? "";
  const params = new URLSearchParams({
    aggregation: selectedFormat, // Send the full format type
  });
  return `${baseUrl}/api/v1/organizations/0/reconciliation/monthly_comparison/csv?${params}`;
};

export type MonthlySummaryPagePresentationProps = {};

export function MonthlySummaryPagePresentation({}: MonthlySummaryPagePresentationProps): React.JSX.Element {
  const [selectedFormat, setSelectedFormat] =
    useState<ReportFormat["type"]>("payer");
  const downloadUrl = getDownloadUrl(selectedFormat);

  return (
    <LayoutV2>
      <Box className="reconciliation-page-layout">
        <div className="monthly-summary-page">
          <Box p="lg">
            <Title>Monthly Summary</Title>
            <Box pt="sm" pb="lg">
              Download AWS cost and usage reports in various formats.
            </Box>
            <Box>
              <Paper p="md" bg="gray.0">
                <Paper p="xl" shadow="sm">
                  <Stack gap="xl" maw={800}>
                    <Box>
                      <Title order={3}>Report Options</Title>
                      <Stack gap="md" mt="md">
                        <Select
                          label="Report Format"
                          description="Choose how you want the data aggregated"
                          value={selectedFormat}
                          onChange={(value) =>
                            setSelectedFormat(value as ReportFormat["type"])
                          }
                          data={REPORT_FORMATS.map((format) => ({
                            value: format.type,
                            label: format.label,
                          }))}
                        />
                      </Stack>
                    </Box>

                    <Box>
                      <Stack gap="xs">
                        <Title order={4}>Selected Report</Title>
                        <Box c="dimmed" size="sm">
                          {
                            REPORT_FORMATS.find(
                              (f) => f.type === selectedFormat,
                            )?.description
                          }
                        </Box>
                      </Stack>
                      <Button
                        component="a"
                        href={downloadUrl}
                        download={`${FILENAME_MAP[selectedFormat]}.csv`}
                        size="md"
                        mt="md"
                        variant="filled"
                        color="blue"
                      >
                        Download Report
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Paper>
            </Box>
          </Box>
        </div>
      </Box>
    </LayoutV2>
  );
}

export type MonthlySummaryPageProps = {};
export default function MonthlySummaryPage({}: MonthlySummaryPageProps): React.JSX.Element {
  return <MonthlySummaryPagePresentation />;
}
