import ScreenLoader from "../components/uikit/ScreenLoader.tsx";
import useToggle from "./useToggle.jsx";
import React from "react";

export const ScreenLoaderContext = React.createContext({ show: false });

export function ScreenLoaderProvider({ children }) {
  const toggle = useToggle(false);

  return (
    <ScreenLoaderContext.Provider value={toggle}>
      <ScreenLoader show={toggle.isOn} />
      {children}
    </ScreenLoaderContext.Provider>
  );
}
