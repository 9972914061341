import { TooltipTrigger } from "react-aria-components";
import React from "react";
import useHoverableTrigger from "./useHoverableTrigger.jsx";

/**
 * Replacement for TooltipTrigger that works with arbitrary elements.
 * For example, using a Link as a tooltip trigger will not work.
 *
 * Note the children of this element need to be:
 * - trigger element
 * - tooltip element
 * If those are not provided this will not work right.
 *
 * @param {number} delay
 * @param {number} closeDelay
 * @param {Array} children See above.
 * @param {*} rest
 * @return {React.JSX.Element}
 * @constructor
 */
export default function HoverableTooltipTrigger({
  delay,
  closeDelay,
  children,
  ...rest
}) {
  const { isOpen, hoverableProps } = useHoverableTrigger({ delay, closeDelay });
  const ref = React.useRef();

  const [trigger, child] = children;
  if (!trigger || !child) {
    console.error(
      "HoverableTooltipTrigger children need to be the trigger and child",
    );
    return null;
  }
  const triggerClone = React.cloneElement(trigger, { ref, ...hoverableProps });
  const childClone = React.cloneElement(child, { triggerRef: ref });

  return (
    <TooltipTrigger isOpen={isOpen} {...rest}>
      {triggerClone}
      <div {...hoverableProps}>{childClone}</div>
    </TooltipTrigger>
  );
}
