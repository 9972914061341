import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import Card, { CardBody } from "./Card";
import Stack from "./Stack";

/**
 * Card with a colored icon on the left, a muted title,
 * and a bolder text.
 * @param {('red'|'green'|'blue'|'grey')} color
 * @param {Icon} icon
 * @param {string} title
 * @param {string} subtitle
 * @param {boolean} contained If true, contain the card in a div.
 *   This is mostly useful where the card is in a flexing stack
 *   that would otherwise stretch.
 * @param {object} rest
 */
export default function StatCard({
  color,
  icon,
  title,
  subtitle,
  contained,
  ...rest
}) {
  let bgcls, fgcls;
  switch (color) {
    case "grey":
      fgcls = "color-foreground";
      bgcls = "bg-light-grey";
      break;
    default:
      fgcls = "color-background";
      bgcls = `bg-${color}`;
  }
  const card = (
    <Card {...rest}>
      <CardBody>
        <Stack row className="align-center">
          <div
            style={{ width: 40, height: 40 }}
            className={clsx(
              bgcls,
              "rounded",
              "flex",
              "align-center",
              "justify-center",
            )}
          >
            <FontAwesomeIcon
              icon={icon}
              className={fgcls}
              style={{ fontSize: 16 }}
            />
          </div>
          <Stack className="ml-3">
            <p className="color-grey mb-1">{title}</p>
            <p className="text-strong">{subtitle}</p>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
  if (contained) {
    return <div>{card}</div>;
  }
  return card;
}
