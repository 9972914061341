import Dropdown from "./Dropdown.jsx";
import Stack from "./Stack";
import "./Filter.css";
import useClientsideSearchParams from "../../state/useClientsideSearchParams.jsx";

export default function Filter({
  label,
  queryParamKey,
  defaultValue,
  children,
  width,
  ...rest
}) {
  const { searchParams, setSearchParam } = useClientsideSearchParams();
  return (
    <Stack row className="filter">
      <label className="filter-label">{label}</label>
      <Dropdown
        placeholder="&nbsp;"
        variant="white"
        {...rest}
        value={searchParams.get(queryParamKey) || defaultValue}
        buttonProps={{ className: "filter-button", style: { width } }}
        onSelectionChange={(v) => setSearchParam(queryParamKey, v)}
      >
        {children}
      </Dropdown>
    </Stack>
  );
}
