import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ResourceRowCard from "../../components/ResourceRowCard";
import spluralize from "../../modules/spluralize.ts";
import categoryIcon from "./categoryIcon.js";
import CommitmentCheckinsChart from "./CommitmentCheckinsChart.jsx";

export default function CommitmentItem({
  detailUrl,
  name,
  category,
  currentCheckin,
  checkins,
  contract,
  ...rest
}) {
  return (
    <ResourceRowCard
      href={detailUrl}
      icon={categoryIcon(category)}
      title={name}
      indicatorNames={[
        currentCheckin?.progress?.tracking,
        contract.activity.name,
      ]}
      subtitle={
        <div className="text-desc color-grey">
          <Link to={contract.detailUrl}>{contract.name}</Link>{" "}
          {expiryText(contract.period)} on{" "}
          {dayjs(contract.period.upper).format("L")}
        </div>
      }
      {...rest}
    >
      <CommitmentCheckinsChart
        checkins={checkins}
        currentCheckin={currentCheckin}
      />
    </ResourceRowCard>
  );
}

function expiryText({ daysUntilEnd }) {
  if (daysUntilEnd === 0) {
    return "expires today";
  } else if (daysUntilEnd < 0) {
    return `expired ${Math.abs(daysUntilEnd)} ${spluralize(
      daysUntilEnd,
      "day",
    )} ago`;
  }
  return `expires in ${daysUntilEnd} ${spluralize(daysUntilEnd, "day")}`;
}
