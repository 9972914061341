import toast from "react-hot-toast";

window.Prism = window.Prism || {};
window.Prism.manual = true;
import "../../assets/vendor/prism.js";
import "../../assets/vendor/prism.css";
import ReactMarkdown from "react-markdown";
import React from "react";
import useMountEffect from "../../state/useMountEffect.jsx";
import "./Markdown.css";
import clsx from "clsx";

export default function Markdown({
  syntaxHighlight,
  children,
  className,
  ...rest
}) {
  const ref = React.useRef();
  useMountEffect(() => {
    if (!syntaxHighlight) {
      return;
    }
    // By highlighting syntax after Markdown renders, we can avoid DOM redraw issues.
    window.Prism.highlightAllUnder(ref.current, false);
    const allLangNodes = [
      ...ref.current.querySelectorAll("pre[class^=language-]"),
    ].concat([...ref.current.querySelectorAll("pre[class^=lang-]")]);
    const btns = allLangNodes.map((langNode) => {
      const copyBtn = document.createElement("button");
      copyBtn.classList.add("btn");
      copyBtn.classList.add("btn-hollow");
      copyBtn.classList.add("btn-md");
      copyBtn.classList.add("markdown-copy");
      copyBtn.style.fontSize = "24px";
      copyBtn.title = "Copy Code";
      copyBtn.onclick = function () {
        navigator.clipboard.writeText(langNode.textContent);
        toast("Copied to clipboard");
      };
      langNode.style.position = "relative";
      langNode.prepend(copyBtn);
      return copyBtn;
    });
    return () => btns.forEach((b) => b.remove());
  });
  return (
    <div ref={ref} className={clsx("markdown-root", className)}>
      <ReactMarkdown {...rest}>{children}</ReactMarkdown>
    </div>
  );
}
