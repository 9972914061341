import clamp from "lodash/clamp";
import clsx from "clsx";
import "./BulletChart.css";

/**
 * Represents a bullet chart with:
 * - measurement/actual usage, represented by the thick bar at center,
 * - target usage, represented with a horizontal line/tick.
 * - groups of target values, represented by gradiated backgrounds.
 *
 * @param {string} className
 * @param {number} width Width of the bar. Default to 48px.
 * @param {number|string} height CSS of the bar. Default to 168px.
 * @param {BulletChartBarProps} rest
 */
type BulletChartProps = {
  className?: string;
  measurement: number;
  width?: number;
  height?: number | string;
  variant?: "blue" | "grey";
  focusMarker?: boolean;
};
export default function BulletChart({
  className,
  measurement,
  width,
  height,
  variant,
  focusMarker,
}: BulletChartProps) {
  measurement = clamp(measurement, 0, 100);
  width = width || 48;
  height = height || 168;
  const variantCls = `bullet-v-${variant || "blue"}`;

  return (
    <div className={clsx("bullet-root", className)} style={{ width, height }}>
      <div className={clsx("bullet-highvalue", variantCls)} />
      {measurement >= 0 && (
        <div
          className={clsx("bullet-measurement", variantCls)}
          style={{ height: `${measurement}%`, width: width * 0.4 }}
        />
      )}
      {focusMarker && (
        <div className={clsx("bullet-focus-marker", variantCls)} />
      )}
    </div>
  );
}

/**
 * @typedef BulletChartBarProps
 * @property {number} measurement Height of the thick center bar.
 *  Value between 0 and 100.
 * @property {number} target Vertical position of the horizontal line/tick.
 *   Value between 0 and 100.
 *   NOTE: The high value is always 100.
 * @property {('blue'|'grey')} variant Which color variant to use. Grey is usually used
 *   for forecasted bars.
 * @property {boolean} focusMarker
 */
