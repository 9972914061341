import isFunction from "lodash/isFunction";

export function arraySetAt<T>(arr: Array<T>, index: number, value: T) {
  const result = arr.splice(0);
  result[index] = value;
  return result;
}

export function arrayMergeAt<T>(
  arr: Array<T>,
  index: number,
  value: T,
): Array<T> {
  const newItem = { ...arr[index], ...value };
  return arraySetAt(arr, index, newItem);
}

export function arrayAppend<T>(arr: Array<T>, value: T): Array<T> {
  const result = arr.splice(0);
  result.push(value);
  return result;
}

export function arrayRemoveAt<T>(arr: Array<T>, index: number): Array<T> {
  const result = arr.slice();
  result.splice(index, 1);
  return result;
}

export function mapSetAt<T>(
  m: Map<string, T>,
  key: string,
  value: T,
): Map<string, T> {
  const result = { ...m, [key]: value };
  return result;
}

/**
 * If value equals target, return ifResult, else return elseResult.
 * Usually used like `ifEqElse(prop, true, defaultVal, prop)`
 * to get a default value if something is true.
 */
export function ifEqElse<T, U>(
  value: any,
  target: any,
  ifResult: T,
  elseResult: U,
): T | U {
  if (value === target) {
    return ifResult;
  }
  return elseResult;
}

/**
 * If value is undefined, return ifUndefined, else return value.
 * Usually used like `defaultUndefined(prop, false)`
 * to return a default if and only if the given value is undefined.
 */
export function defaultUndefined<T, U>(value: T, ifUndefined: U): T | U {
  if (value === undefined) {
    return ifUndefined;
  }
  return value;
}

export function invokeIfFunc(f: Function | unknown, ...args: unknown[]) {
  if (isFunction(f)) {
    return f(...args);
  }
  return f;
}
