import {
  faCircleChevronDown,
  faCircleChevronRight,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Flex, Paper, Text } from "@mantine/core";
import { sortBy } from "lodash";
import React from "react";
import {
  DiscountDiscrepancyHierarchy,
  ServiceDDHierarchy,
  ServiceGroupDDHierarchy,
  UsageTypeDDHierarchy,
} from "../../apiQueries/reconciliation";
import useToggle from "../../state/useToggle";
import { RegionLine } from "../RegionLine/RegionLine";
import "./DiscountDiscrepancyList.css";

const NiceDollars = ({ amount }: { amount: number }): React.JSX.Element => {
  const dollars = Math.floor(amount);
  const dollarsString = dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  const cents = Math.floor((amount - dollars) * 100);
  const centsString = cents.toString().padStart(2, "0");
  return (
    <span>
      {dollarsString}.
      <Text span c="gray" fz="inherit" lh="inherit">
        {centsString}
      </Text>
    </span>
  );
};

const CollapsibleTree = ({
  labelLeft,
  labelRight,
  children,
  level = 0,
  border = false,
}: {
  labelLeft: string | React.JSX.Element;
  labelRight: string | React.JSX.Element;
  children: React.JSX.Element;
  level?: number;
  border?: boolean;
}): React.JSX.Element => {
  const { isOn: expanded, toggle } = useToggle(false);
  return (
    <Paper
      style={{ paddingLeft: `${level * 8}px` }}
      mt="sm"
      pt="sm"
      pb={expanded ? "md" : 0}
      pr="sm"
      mr="-sm"
      fz="lg"
      shadow="none"
      withBorder={border && expanded}
    >
      <Flex onClick={toggle} className="tree-labels" align="center">
        {expanded ? (
          <FontAwesomeIcon icon={faCircleChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faCircleChevronRight} />
        )}
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          ml="xs"
          fz="inherit"
          w="100%"
        >
          <Box>{labelLeft}</Box>
          <Box>{labelRight}</Box>
        </Flex>
      </Flex>
      {expanded && children}
    </Paper>
  );
};

const DiscrepancyRollup = ({
  discrepancies,
}: {
  discrepancies: number;
}): React.JSX.Element => {
  if (discrepancies === 0) {
    return <></>;
  }
  return (
    <Text
      span
      bg="red.1"
      py="4"
      px="6"
      fw="bold"
      style={{ borderRadius: "8px" }}
      mr="xs"
    >
      {discrepancies}
    </Text>
  );
};

const DDUsageType = ({
  usageType,
}: {
  usageType: UsageTypeDDHierarchy;
}): React.JSX.Element => {
  const discountDiscrepancies = sortBy(
    usageType.discountDiscrepancies,
    (discountDiscrepancy) => -discountDiscrepancy.amazonNetCost,
  );
  return (
    <CollapsibleTree
      labelLeft={usageType.usageTypeName ?? "Unknown"}
      labelRight={
        <>
          <DiscrepancyRollup discrepancies={usageType.totals.discrepancies} />
          <NiceDollars amount={usageType.totals.amazonNetCost} />
        </>
      }
      level={3}
    >
      <>
        {discountDiscrepancies.map((discountDiscrepancy) => {
          return (
            <Box
              my="xs"
              key={discountDiscrepancy.lineItemUsageTypeWithoutRegion}
            >
              <RegionLine
                discountDiscrepancy={discountDiscrepancy}
                key={discountDiscrepancy.productRegionCode}
              />
            </Box>
          );
        })}
      </>
    </CollapsibleTree>
  );
};
const DDService = ({
  service,
}: {
  service: ServiceDDHierarchy;
}): React.JSX.Element => {
  const usageTypes = sortBy(
    service.usageTypes,
    (usageType) => -usageType.totals.amazonNetCost,
  );
  return (
    <CollapsibleTree
      labelLeft={service.serviceName ?? "Unknown"}
      labelRight={
        <>
          <DiscrepancyRollup discrepancies={service.totals.discrepancies} />
          <NiceDollars amount={service.totals.amazonNetCost} />
        </>
      }
      level={2}
      border
    >
      <>
        {usageTypes.map((usageType) => {
          return (
            <DDUsageType
              usageType={usageType}
              key={usageType.usageTypeName ?? "Unknown"}
            />
          );
        })}
      </>
    </CollapsibleTree>
  );
};

const DDServiceGroup = ({
  serviceGroup,
}: {
  serviceGroup: ServiceGroupDDHierarchy;
}): React.JSX.Element => {
  const services = sortBy(
    serviceGroup.services,
    (service) => -service.totals.amazonNetCost,
  );
  return (
    <CollapsibleTree
      labelLeft={serviceGroup.serviceGroupName ?? "Unknown"}
      labelRight={
        <>
          <DiscrepancyRollup
            discrepancies={serviceGroup.totals.discrepancies}
          />
          <NiceDollars amount={serviceGroup.totals.amazonNetCost} />
        </>
      }
      level={1}
    >
      <>
        {services.map((service) => {
          return (
            <DDService
              service={service}
              key={service.serviceName ?? "Unknown"}
            />
          );
        })}
      </>
    </CollapsibleTree>
  );
};

export type DiscountDiscrepancyListProps = {
  discountDiscrepanciesHierarchy: DiscountDiscrepancyHierarchy;
};
export default function DiscountDiscrepancyList({
  discountDiscrepanciesHierarchy,
}: DiscountDiscrepancyListProps): React.JSX.Element {
  const serviceGroups = sortBy(
    discountDiscrepanciesHierarchy,
    (serviceGroup) => -serviceGroup.totals.amazonNetCost,
  );
  return (
    <div className="discount-discrepancy-list">
      {serviceGroups.map((serviceGroup) => {
        return (
          <DDServiceGroup
            serviceGroup={serviceGroup}
            key={serviceGroup.serviceGroupName ?? "Unknown"}
          />
        );
      })}
    </div>
  );
}
