import Stack from "./uikit/Stack";
import Button from "./uikit/Button";
import ScreenLoader from "./uikit/ScreenLoader.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "./icons.jsx";

export default function RelatedList({
  title,
  subtitle,
  children,
  viewAllText,
  viewAllHref,
  loading,
  error,
}) {
  return (
    <Stack col gap={3}>
      <Stack row className="justify-between align-center">
        <div>
          <h5>{title}</h5>
          {subtitle && <p className="color-grey">{subtitle}</p>}
        </div>
        <Button href={viewAllHref} rightArrow>
          {viewAllText}
        </Button>
      </Stack>
      {loading && <ScreenLoader scrim={false} height={150} />}
      {!loading && children}
      {error && (
        <Stack col className="justify-center mt-4 text-center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="color-red mb-4"
            style={{ fontSize: 40 }}
          />
          Sorry, something went wrong. You can refresh the page to try again.
        </Stack>
      )}
    </Stack>
  );
}
