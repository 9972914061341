import last from "lodash/last.js";
import BulletMeterChart from "../../components/uikit/charts/BulletMeterChart.jsx";
import ConsumptionProgressPill from "../../components/uikit/charts/ConsumptionProgressPill.jsx";
import ConsumptionTimelineChart from "../../components/uikit/charts/ConsumptionTimelineChart.jsx";
import Markdown from "../../components/uikit/Markdown.jsx";
import Stack from "../../components/uikit/Stack";

export default function CommitmentCheckinsChart({ checkins, currentCheckin }) {
  if (!checkins || checkins.length === 0) {
    return null;
  }
  if (checkins.length === 1) {
    return (
      <ConsumptionTimelineChart
        progress={checkins[0].progress}
        period={checkins[0].period}
      />
    );
  }
  const detailed = currentCheckin || last(checkins);
  // This should move to the backend once we can better name what
  // each of these values represent, like the implicit 'rightmost' value
  // which isn't even labeled.
  const rightmostValue =
    Math.max(detailed.progress.excessValue, detailed.progress.currentValue) *
    1.1;
  const toPerc = (x) => (x / rightmostValue) * 100;
  let pillWidth = "40px";
  if (checkins.length < 6) {
    pillWidth = "120px";
  } else if (checkins.length < 12) {
    pillWidth = "85px";
  } else if (checkins.length < 20) {
    pillWidth = "70px";
  }

  return (
    <Stack gap={2}>
      <BulletMeterChart
        measurement={toPerc(detailed.progress.currentValue)}
        measurementText={detailed.progress.currentValueFormatted}
        measurementTooltip={
          <Markdown>{detailed.progress.tooltips.currentValueMd}</Markdown>
        }
        lowValue={toPerc(detailed.progress.minimumTargetValue)}
        target={toPerc(detailed.progress.maxValue)}
        targetText={detailed.progress.maxValueFormatted}
        targetTooltip={
          <Markdown>{detailed.progress.tooltips.maxValueMd}</Markdown>
        }
        targetTracking={detailed.progress.tracking}
      />
      <Stack row gap={2} style={{ flexWrap: "wrap" }}>
        {checkins.map(({ id, progress, period }) => (
          <ConsumptionProgressPill
            key={id}
            period={period}
            progress={progress}
            style={{ maxWidth: pillWidth }}
            tooltip={<Markdown>{progress.tooltips.periodSummaryMd}</Markdown>}
            tooltipProps={{ placement: "bottom" }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
