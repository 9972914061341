import React from "react";
import api from "../../api";
import ErrorScreen from "../../components/ErrorScreen";
import Form from "../../components/uikit/Form.jsx";
import FormButtons from "../../components/uikit/FormButtons.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import TextField from "../../components/uikit/TextField.jsx";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";
import useRoleAccess from "../../state/useRoleAccess.tsx";

import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import Hr from "../../components/uikit/Hr";
import PageHeading from "../../components/uikit/PageHeading.jsx";
import useFormSubmitter from "../../state/useFormSubmitter.js";

export default function OrgNotificationsPage() {
  const { fetchedState, loading, error, fetched, replaceState } = useAsyncFetch(
    api.getOrganization,
  );

  return (
    <LayoutV2 variant="sidebar">
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack className="page-content" col gap={5} style={{ maxWidth: 500 }}>
            <PageHeading>Notifications</PageHeading>
            <Hr />
            <Notifications
              notificationEmails={fetchedState.notificationEmails}
              onOrganizationChanged={(st) => replaceState(st)}
            />
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function Notifications({ notificationEmails, onOrganizationChanged }) {
  const [emailStringMemo, setEmailStringMemo] = React.useState("");
  React.useMemo(
    () => setEmailStringMemo(notificationEmails),
    [notificationEmails],
  );

  const {
    prepareSubmit,
    showErrorToast,
    showSuccessToast,
    turnOffScreenLoader,
  } = useFormSubmitter();
  const { cannotWrite } = useRoleAccess();

  function handleSubmit(e) {
    prepareSubmit(e);
    api
      .updateOrganization({ notificationEmails: emailStringMemo })
      .then((r) => onOrganizationChanged(r.data))
      .then(() => showSuccessToast())
      .catch(showErrorToast)
      .finally(turnOffScreenLoader);
  }
  const disabled = cannotWrite("orgsettings");

  return (
    <>
      <p className="text">
        Notifications will be sent to all account admins, plus any emails on
        this list.
      </p>
      <Form onSubmit={handleSubmit} style={{ padding: "24px" }}>
        <TextField
          wide
          label="Emails"
          extra="Seperate emails with commas."
          value={emailStringMemo}
          disabled={disabled}
          onChange={(e) => setEmailStringMemo(e.target.value)}
        />
        <FormButtons
          type="submit"
          disabled={disabled}
          infoMessage={
            disabled && "Only admins can modify email notifications."
          }
        >
          Submit
        </FormButtons>
      </Form>
    </>
  );
}
