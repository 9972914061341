import { withSentry } from "./sentry";

type LoggerOptions = {
  tags?: Logger["_tags"];
  context?: Logger["_context"];
};
export class Logger {
  name: string;
  _tags: Record<string, unknown>;
  _context: Record<string, unknown>;

  constructor(name: string, options?: LoggerOptions) {
    const { tags, context } = options || {};
    this.name = name;
    this._tags = tags || {};
    this._context = context || {};
  }

  tags(tags: Logger["_tags"]) {
    return new Logger(this.name, {
      tags: { ...this._tags, ...tags },
      context: this._context,
    });
  }

  context(context: Logger["_context"]) {
    return new Logger(this.name, {
      tags: this._tags,
      context: { ...this._context, ...context },
    });
  }

  debug(event: string, fields?: Record<string, unknown>) {
    console.debug(...this._buildConsoleMsg(event, fields));
  }

  info(event: string, fields?: Record<string, unknown>) {
    console.log(...this._buildConsoleMsg(event, fields));
  }

  error(event: string, fields?: Record<string, unknown>) {
    console.error(...this._buildConsoleMsg(event, fields));
    this._withSentry(function (sentry, ctx) {
      const allctx = { ...fields, ...ctx };
      sentry.captureMessage(event, allctx);
    });
  }

  exception(event: string, exc: Error, fields: Record<string, unknown>) {
    this._withSentry(function (sentry, ctx) {
      sentry.captureException(exc, ctx);
    });
    console.error(...this._buildConsoleMsg(event, fields));
  }

  _buildConsoleMsg(event: string, fields?: Record<string, unknown>) {
    fields = fields || {};
    const arr = [`[${this.name}]`, event];
    // Tags first because context can be big
    Object.entries(this._tags).forEach(([k, v]) =>
      arr.push(`${k}=${stringifyNonString(v)}`),
    );
    const ctx = { ...this._context, ...fields };
    Object.entries(ctx).forEach(([k, v]) =>
      arr.push(`${k}=${stringifyNonString(v)}`),
    );
    return arr;
  }

  _withSentry(callback: (sentry: any, ctx: Record<string, unknown>) => void) {
    withSentry((sentry) => {
      callback(sentry, {
        tags: { logger: this.name, ...this._tags },
        extra: this._context,
      });
    });
  }
}

function stringifyNonString(o: unknown) {
  return typeof o === "string" ? o : JSON.stringify(o);
}

window.CiroLogger = Logger;

declare global {
  interface Window {
    CiroLogger: typeof Logger;
  }
}
