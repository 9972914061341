import useMountEffect from "./useMountEffect";
import React from "react";
import toast from "react-hot-toast";
import { ScreenLoaderContext } from "./ScreenLoaderProvider";

/**
 * @returns {Toggle}
 */
export default function useScreenLoader() {
  return React.useContext(ScreenLoaderContext);
}

export function withScreenLoaderMount(show) {
  show = show || false;
  return (Wrapped) => {
    return function ScreenLoader(props) {
      const loader = useScreenLoader();
      useMountEffect(() => {
        loader.setState(show);
        toast.remove();
      });
      return <Wrapped {...props} />;
    };
  };
}
