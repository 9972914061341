import "./InputFieldLabel.css";
import clsx from "clsx";
import React from "react";
import { Button, Label, TooltipTrigger } from "react-aria-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "../icons.jsx";
import Tooltip from "./Tooltip.jsx";

export default function InputFieldLabel({
  label,
  children,
  className,
  tooltip,
  ...rest
}) {
  let tooltipEl = null;
  const triggerRef = React.useRef();
  if (tooltip) {
    tooltipEl = (
      <TooltipTrigger delay={0}>
        <Button className="input-field-tooltip">
          <FontAwesomeIcon ref={triggerRef} icon={faQuestionCircle} />
        </Button>
        <Tooltip placement="right">{tooltip}</Tooltip>
      </TooltipTrigger>
    );
  }

  return (
    <Label className={clsx("input-field-label", className)} {...rest}>
      {label || children}
      {tooltipEl}
    </Label>
  );
}
