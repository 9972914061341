import isUndefined from "lodash/isUndefined";
import clamp from "lodash/clamp";
import clsx from "clsx";
import "./SimpleBulletChart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Represent a simple bullet chart, with a measure as a percentage of the total
 * (`complete` param), and an optional target (`vertical` param).
 *
 * @param {string} className
 * @param {('linear'|'ring')} shape
 * @param {ThemeColor=} fill Filled chart color. Default to grey.
 * @param {ThemeColor=} empty Empty chart color. Default to secondary (lighter grey).
 * @param {ThemeColor=} color Use this for 'fill' and a -light version for 'empty'.
 * @param {number} complete Value complete between 0 and 100.
 * @param {number} ringSize Width of the ring chart. Ignored for 'linear'.
 * @param {object} vertical Render the vertical bar or visual element.
 * @param {number} vertical.at Value beetween 0 and 100 where the vertical bar is placed.
 * @param {Icon=} vertical.icon Render this icon on top of the bar.
 * @param {string=} vertical.title Use this as the 'title' attribute for the vertical.
 */
export default function SimpleBulletChart({
  className,
  shape,
  fill,
  empty,
  color,
  complete,
  ringSize,
  vertical,
}) {
  shape = shape || "linear";
  if (color) {
    fill = fill || color;
    empty = empty || `${color}-light`;
  } else {
    fill = fill || "grey";
    empty = empty || "secondary";
  }
  complete = clamp(isUndefined(complete) ? 50 : complete, 0, 100);
  const props = { className, fill, empty, complete };
  if (shape === "linear") {
    return Linear({ vertical, ...props });
  }
  return Donut({ ringSize, ...props });
}

function Linear({ className, fill, empty, complete, vertical }) {
  return (
    <div className={clsx("bullet-linear-root", className)}>
      <div className={clsx("bullet-linear-empty", `bg-${empty}`)} />
      <div
        className={clsx("bullet-linear-fill", `bg-${fill}`)}
        style={{ width: `${complete}%` }}
      />
      {vertical && (
        <div
          className={clsx("bullet-linear-vertical-line")}
          style={{ left: `${vertical.at}%` }}
          title={vertical.title}
        >
          {vertical.icon && (
            <FontAwesomeIcon
              icon={vertical.icon}
              className="bullet-linear-vertical-icon"
            />
          )}
        </div>
      )}
    </div>
  );
}

/**
 * Based on https://stackoverflow.com/a/48181786
 */
function Donut({ className, fill, empty, complete, ringSize }) {
  // Same thickness as the linear chart for now.
  const strokeWidth = ringSize / 5;
  // ringSize is one edge of the box, we need half for radius work.
  const halfsize = ringSize * 0.5;
  // Effective radius is half the box size, minus the half stroke that's outside the circle.
  const rad = halfsize - strokeWidth * 0.5;
  // Circumference is 2 * PI * radius. Needed for dash stuff in the SVG.
  const circum = 2 * Math.PI * rad;
  // It's easier to swap what fill and empty mean, given how dashes work.
  const invertCompleteRatio = 1 - complete / 100;
  const strokeDashoffset = circum * invertCompleteRatio;
  return (
    <div className={clsx("bullet-donut-root", className)}>
      <svg
        width={ringSize}
        height={ringSize}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Rotate the circle so we start at the bottom */}
        <g transform={`rotate(90, ${halfsize}, ${halfsize})`}>
          <title>Donut Chart</title>
          <circle
            id="circle"
            style={{ strokeDashoffset: 0 }}
            r={rad}
            cy={halfsize}
            cx={halfsize}
            strokeDasharray={circum}
            strokeWidth={strokeWidth}
            stroke={`var(--color-${empty})`}
            fill="none"
          />
          <circle
            id="circle"
            style={{ strokeDashoffset }}
            r={rad}
            cy={halfsize}
            cx={halfsize}
            strokeDasharray={circum}
            strokeWidth={strokeWidth}
            stroke={`var(--color-${fill})`}
            fill="none"
          />
        </g>
      </svg>
    </div>
  );
}
