import {
  ComponentClass,
  ComponentProps,
  ComponentType,
  ReactComponentElement,
} from "react";
import { Helmet } from "react-helmet-async";

export default function withMetatags({
  title,
  exact,
}: {
  title: string;
  exact?: boolean;
}) {
  const customTitle = title ? `${title} | Skyway` : "Skyway";
  return (Wrapped: ComponentType): ComponentType => {
    return function WithMetatags(props: ComponentProps<ComponentType>) {
      return (
        <>
          <Helmet>
            <title>{exact ? title : customTitle}</title>
          </Helmet>
          <Wrapped {...props} />
        </>
      );
    };
  };
}
