import { Navigate, useParams } from "react-router-dom";
import api from "../../api";
import useAsyncFetch from "../../state/useAsyncFetch.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";

export default function InvitationJumpPage() {
  const { id } = useParams();
  const { fetchedState, error, fetched } = useAsyncFetch(api.getInvitation, {
    fetchArgs: { id },
  });

  if (error) {
    return <Navigate to="/" />;
  }

  if (fetched) {
    const { action } = fetchedState;
    if (action === "login") {
      return <Navigate to="/" state={{ invitation: fetchedState }} />;
    } else if (action === "register") {
      return <Navigate to="/register" state={{ invitation: fetchedState }} />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return <ScreenLoader />;
}
