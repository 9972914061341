import { Tab, Tabs as RATabs, TabList } from "react-aria-components";
import "./Tabs.css";
import clsx from "clsx";

export default function Tabs({
  className,
  items,
  selectedKey,
  onSelectionChange,
}) {
  if (!items) {
    return null;
  }
  return (
    <RATabs
      className={clsx("tabs", className)}
      selectedKey={selectedKey}
      onSelectionChange={onSelectionChange}
    >
      <TabList aria-label="Tabs" className="tabs-list">
        {items.map(({ label, href }) => (
          <Tab
            key={href}
            id={href}
            className={clsx("tabs-tab", selectedKey === href && "selected")}
          >
            <a
              className="tabs-tab-link"
              onClick={(e) => e.preventDefault()}
              href={href}
            >
              {label}
            </a>
          </Tab>
        ))}
      </TabList>
    </RATabs>
  );
}
