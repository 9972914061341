import Stack from "./uikit/Stack";
import React from "react";

/**
 * Container for a stack of ResourceRowCards.
 * Has to do some magic to align the right 'content' area across all cards.
 *
 * - Lay out all the children.
 * - Children use a useLayoutEffect hook to report their width before layout.
 * - When all children have reported their width, the maximum width is taken.
 * - This width is sent back down to the children as their 'forced' 'width.
 * - If the 'forced' width is set, the children no longer report,
 *   avoiding a loop.
 *
 * @param {Array<ResourceRowCard>} children
 */
export default function ResourceRowList({ children }) {
  const widths = React.useRef([]);
  const [forcedWidth, setForcedWidth] = React.useState(0);

  const handleLayout = React.useCallback(
    ({ detailsAreaWidth }) => {
      widths.current.push(detailsAreaWidth);
      if (widths.current.length === children.length) {
        setForcedWidth(Math.max(...widths.current));
      }
    },
    [children.length],
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }
    return React.cloneElement(child, {
      implOnLayout: handleLayout,
      implForcedWidth: forcedWidth,
    });
  });
  return (
    <Stack col gap={3}>
      {childrenWithProps}
    </Stack>
  );
}
