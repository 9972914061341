import reduceRight from "lodash/reduceRight";
import { ComponentClass, ComponentType } from "react";

export type HOC = (component: ComponentType) => ComponentType;
export default function applyHocs(...funcs: (HOC | ComponentType)[]) {
  return reduceRight(
    funcs,
    (memo: ComponentType, f: HOC | ComponentType) => {
      // This is very type unsafe, but I can't find an elegant way to do better
      // without rewriting all uses of this function.
      return (f as HOC)(memo);
    },
    funcs.pop() as ComponentType,
  );
}
