import api from "../api";
import ErrorScreen from "../components/ErrorScreen";
import LayoutV2 from "../components/LayoutV2/LayoutV2";
import Button from "../components/uikit/Button";
import Card from "../components/uikit/Card";
import Hr from "../components/uikit/Hr";
import PageHeading from "../components/uikit/PageHeading";
import ScreenLoader from "../components/uikit/ScreenLoader";
import Stack from "../components/uikit/Stack";
import useAsyncFetch from "../state/useAsyncFetch.jsx";

export default function DashboardPage() {
  const {
    fetchedState: state,
    loading,
    error,
    fetched,
  } = useAsyncFetch(api.getDashboard);
  return (
    <LayoutV2>
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <Stack col gap={5} className="p-5" style={{ maxWidth: 1200 }}>
          <PageHeading>Welcome to Skyway</PageHeading>
          <Hr />
          <Stack row gap={5} className="flex-wrap">
            {state.issues.map(({ key, message, cta, url }: any, i: number) => (
              <IssueCard
                key={`${key}${i}`}
                message={message}
                cta={cta}
                url={url}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </LayoutV2>
  );
}

function IssueCard({ message, cta, url }: any) {
  return (
    <Card style={{ maxWidth: 364 }} className="p-5">
      <Stack col className="justify-between h-100">
        <p className="mb-4">{message}</p>
        <Button href={url} rightArrow variant="hollow">
          {cta}
        </Button>
      </Stack>
    </Card>
  );
}
