import clsx from "clsx";
import "./Hr.css";
import { ifEqElse } from "../../modules/fp";

type HrProps = {
  className?: string;
  mt?: number;
  mb?: number;
  my?: number;
} & React.HTMLProps<HTMLHRElement>;
export default function Hr({ className, mt, mb, my, ...rest }: HrProps) {
  return (
    <hr
      className={clsx(
        "hr",
        ifEqElse(mb, true, "mb-3", `mb-${mb}`),
        ifEqElse(mt, true, "mt-3", `mt-${mt}`),
        ifEqElse(my, true, "my-3", `my-${my}`),
        className,
      )}
      {...rest}
    />
  );
}
