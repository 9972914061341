import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";

type AccessSetting = "read" | "write";
type RoleAccess = {
  orgsettings: AccessSetting[];
  orgmembers: AccessSetting[];
  curingest: AccessSetting[];
  contractmanager: AccessSetting[];
  contracts: AccessSetting[];
  commitments: AccessSetting[];
  billing: AccessSetting[];
};
export type Organization = {
  active: boolean;
  id: number;
  invitedAt: string | null;
  key: string;
  name: string;
  roleAccess: RoleAccess;
  roleName: string;
};
type UserRoleAccess = {
  skywayAdmin: string[];
  reconciliation: string[];
};
export type User = {
  activeOrganization: Organization;
  adminUrl: string;
  availableOrganizations: Organization[];
  avatarSrc: string;
  email: string;
  id: number;
  invitedOrganizations: any[];
  name: string;
  userRoleAccess: UserRoleAccess;
};
const rw: AccessSetting[] = ["read", "write"];
export const organizationFactory = Factory.define<Organization>(() => ({
  active: true,
  id: 1,
  invitedAt: "2021-01-01",
  key: "key",
  name: "Some Org",
  roleAccess: {
    orgsettings: rw,
    orgmembers: rw,
    curingest: rw,
    contractmanager: rw,
    contracts: rw,
    commitments: rw,
    billing: rw,
  },
  roleName: "some role",
}));

export const userFactory = Factory.define<User>(() => ({
  activeOrganization: organizationFactory.build(),
  adminUrl: "admin_url",
  availableOrganizations: [organizationFactory.build()],
  avatarSrc: "https://robohash.org/1?set=set1&bgset=bg1&size=200x200",
  email: "email",
  id: 1,
  invitedOrganizations: [],
  name: "Sam",
  userRoleAccess: {
    skywayAdmin: rw,
    reconciliation: rw,
  },
}));

export const getMe = () => apiRequest.get<User>("/api/v1/me");

export const postActiveOrganization = (data: { organizationId: number }) => {
  return apiRequest.post<User>(`/api/v1/me/active_organization`, data);
};
