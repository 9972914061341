import axios, { AxiosError, AxiosRequestConfig, CanceledError } from "axios";
import humps from "humps";
import get from "lodash/get";
import noop from "lodash/noop";
import {
  debugRequestLogger,
  debugResponseLogger,
  errorResponseLogger,
} from "./apilogger";
import bluejay from "./bluejay";

// Axios's types for `defaultTransformResponse` and `transformResponse` are not
// compatible, so we need to make them consistent.
function coerceTransformer<t>(transformer: t | t[] | undefined): t[] {
  const definedTransformer = transformer ?? [];
  return Array.isArray(definedTransformer)
    ? definedTransformer
    : [definedTransformer];
}

type ApiBaseConfig = {
  debug: boolean;
  chaos: boolean | number;
};
function create(apiHost: string, config: ApiBaseConfig) {
  const { debug, chaos, ...rest } = config || {};
  const instance = axios.create({
    baseURL: apiHost,
    timeout: 20000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    transformResponse: [
      ...coerceTransformer(axios.defaults.transformResponse),
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...coerceTransformer(axios.defaults.transformRequest),
    ],
    ...rest,
  });
  if (debug) {
    console.log(
      "apiBase: Debug mode enabled, setting up Axios logging for calls to",
      apiHost,
    );
    instance.interceptors.request.use(...debugRequestLogger);
    instance.interceptors.response.use(...debugResponseLogger);
  } else {
    instance.interceptors.response.use(...errorResponseLogger);
  }
  if (chaos) {
    console.log(
      `apiBase: Chaos mode enabled (${chaos}), adding random delays to api calls`,
    );
    instance.interceptors.request.use(requestChaos(chaos));
  }
  return instance;
}

function requestChaos(chaos: boolean | number) {
  const chaosMult = isNaN(Number(chaos)) ? 1 : Number(chaos);
  return (reqConfig: AxiosRequestConfig) => {
    // Add some delay into api calls to simulate real-world behavior.
    let debugDelay = 250 + Math.random() * 1000;
    // Add some p90 and p95 latencies
    const percentile = Math.random();
    if (percentile < 0.05) {
      debugDelay += 3000 + Math.random() * 4000;
    } else if (percentile < 0.1) {
      debugDelay += 1000 + Math.random() * 2000;
    }
    debugDelay *= chaosMult;
    return bluejay.Promise.resolve(reqConfig).delay(debugDelay);
  };
}

function handleStatus(status: string, cb: (error: AxiosError) => void) {
  return (error: AxiosError) => {
    if (get(error, "response.data.error.status") === status) {
      return cb(error);
    }
    throw error;
  };
}

function mergeParams(params: Object, o: Object | undefined) {
  const cased = humps.decamelizeKeys(params || {});
  return { params: cased, ...o };
}

function isAxiosTimeout(r: AxiosError) {
  if (r instanceof CanceledError) {
    return true;
  }
  if (r instanceof AxiosError && r.code === "ECONNABORTED") {
    return true;
  }
  return false;
}

export default {
  create,
  handleStatus,
  isAxiosTimeout,
  mergeParams,
  pick: (s: string) => (o: Object) => get(o, s),
  pickData: (o: { data: unknown }) => o.data,
  swallow: (status: string) => handleStatus(status, noop),
};
