import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {
  faCalendarTimesSolid,
  faClockSolid,
  faCreditCardSolid,
  faFileInvoiceSolid,
  faLinkSolid,
  faQuestion,
  faUploadSolid,
  faWavePulseSolid,
} from "../icons.jsx";
import "./Indicator.css";

type IndicatorProps = {
  className?: string;
  name?: string;
};
export default function Indicator({ className, name }: IndicatorProps) {
  const { label, icon, color, bg } = nameProps[
    (name ?? "").replace("_", "-")
  ] ?? {
    label: name,
    icon: faQuestion,
    bg: "red",
    color: "red",
  };
  const iconColorCls = `color-${color}`;
  const bgCls =
    bg === "white"
      ? "indicator-white"
      : clsx("indicator-fill", `indicator-${color}`);
  const ico =
    icon === bullet ? (
      <span className={clsx("indicator-bullet", iconColorCls)}>&bull;</span>
    ) : (
      <FontAwesomeIcon
        className={clsx("indicator-icon", iconColorCls)}
        icon={icon}
      />
    );
  return (
    <div className={clsx("indicator", bgCls, className)}>
      {ico}
      <span className="text">{label}</span>
    </div>
  );
}

const bullet = "bullet";

type NameProp = {
  label: string;
  icon: IconProp | "bullet";
  bg: string;
  color: string;
};
const nameProps: Record<string, NameProp> = {
  "under-target": {
    label: "At Risk",
    icon: bullet,
    bg: "fill",
    color: "orange",
  },
  "on-target": {
    label: "On Target",
    icon: bullet,
    bg: "fill",
    color: "green",
  },
  "over-target": {
    label: "Over",
    icon: bullet,
    bg: "fill",
    color: "red",
  },
  active: {
    label: "Active",
    icon: faWavePulseSolid,
    bg: "white",
    color: "blue",
  },
  future: {
    label: "Future",
    icon: faClockSolid,
    bg: "white",
    color: "purple",
  },
  expired: {
    label: "Expired",
    icon: faCalendarTimesSolid,
    bg: "white",
    color: "red",
  },
  importing: {
    label: "Importing",
    icon: faUploadSolid,
    bg: "white",
    color: "grey",
  },
  new: {
    label: "New",
    icon: faLinkSolid,
    bg: "white",
    color: "grey",
  },
  invalid: {
    label: "Invalid",
    icon: faLinkSolid,
    bg: "white",
    color: "red",
  },
  valid: {
    label: "Valid",
    icon: faLinkSolid,
    bg: "white",
    color: "green",
  },
  trial: {
    label: "Trial",
    icon: faClockSolid,
    bg: "fill",
    color: "orange",
  },
  invoice: {
    label: "Invoiced",
    icon: faFileInvoiceSolid,
    bg: "fill",
    color: "green",
  },
  saas: {
    label: "Subscription",
    icon: faCreditCardSolid,
    bg: "fill",
    color: "green",
  },
};
nameProps.current = nameProps.active;
