import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import clamp from "lodash/clamp";
import React from "react";
import { TooltipTrigger } from "react-aria-components";
import { faBullseyeSolid, faFlagCheckeredSolid } from "../../icons.jsx";
import Tooltip from "../Tooltip.jsx";
import useHoverableTrigger from "../useHoverableTrigger.jsx";
import "./BulletMeterChart.css";

/**
 * Represents a bullet chart with:
 * - measurement/actual usage, represented by the thick bar at center,
 * - target usage, represented with a horizontal line/tick.
 * - groups of target values, represented by gradiated backgrounds.
 *
 * @param {string} className
 * @param {number|string} height CSS height of the bar. Default to 42px.
 * @param {number} measurement Horizontal size of the thick center bar.
 *  Value between 0 and 100.
 * @param {string} measurementText Label of the measurement bar.
 * @param {*} measurementTooltip Tooltip hovering over the measurement bar.
 * @param {number} target Horizontal location of the horizontal line/tick.
 *   Value between 0 and 100.
 * @param {string} targetText Label of the target line. Gets an icon if given.
 * @param {ProgressTracking} targetTracking
 * @param {*} targetTooltip Tooltip hovering over the target line and text/icon.
 * @param {number} midValue Horizontal location of the 'medium' value gradation.
 *   The mid value shows between the lowValue (being the left of the low value bar)
 *   and midValue.
 *   NOTE: The high value is always 100.
 * @param {string} midValueText Label of the mid-value dashed line. Gets an icon if given.
 * @param {*} midValueTooltip Tooltip hovering over the dashed line and its text/icon.
 */
export default function BulletMeterChart({
  className,
  measurement,
  measurementText,
  measurementTooltip,
  target,
  targetText,
  targetTooltip,
  targetTracking,
  midValue,
  midValueText,
  midValueTooltip,
  height,
}) {
  measurement = clamp(measurement, 0, 100);
  target = clamp(target, 0, 100);
  midValue = clamp(midValue, 0, 100);
  height = height || 42;
  let targetColor = targetTracking === "under_target" ? "bg-red" : "bg-green";
  const midValueTextRef = React.useRef();
  const targetTextRef = React.useRef();
  const {
    isOpen: midValueTooltipOpen,
    hoverableProps: midValueHoverableProps,
  } = useHoverableTrigger({
    disabled: !midValueTooltip,
  });
  const { isOpen: targetTooltipOpen, hoverableProps: targetHoverableProps } =
    useHoverableTrigger({
      disabled: !targetTooltip,
    });
  const midValueTooltipEl = midValueTooltip && (
    <Tooltip
      placement="right"
      isOpen={midValueTooltipOpen}
      triggerRef={midValueTextRef}
    >
      {midValueTooltip}
    </Tooltip>
  );
  const targetTooltipEl = targetTooltip && (
    <Tooltip
      placement="left"
      isOpen={targetTooltipOpen}
      triggerRef={targetTextRef}
    >
      {targetTooltip}
    </Tooltip>
  );
  return (
    <div className={clsx("bulletmeter-root", className)}>
      {(targetText || midValueText) && (
        <div className="bulletmeter-labels">
          <div>&nbsp;</div>
          {targetText && (
            <TooltipTrigger isOpen={targetTooltipOpen}>
              <div
                ref={targetTextRef}
                className="bulletmeter-label"
                style={{ left: `${target}%` }}
                {...targetHoverableProps}
              >
                {targetText}
                <FontAwesomeIcon
                  icon={faBullseyeSolid}
                  className="bulletmeter-label-icon"
                />
              </div>
              {targetTooltipEl}
            </TooltipTrigger>
          )}
          {midValueText && (
            <TooltipTrigger isOpen={midValueTooltipOpen}>
              <div
                ref={midValueTextRef}
                className="bulletmeter-label"
                style={{ left: `${midValue}%` }}
                {...midValueHoverableProps}
              >
                {midValueText}
                <FontAwesomeIcon
                  icon={faFlagCheckeredSolid}
                  className="bulletmeter-label-icon"
                />
              </div>
              {midValueTooltipEl}
            </TooltipTrigger>
          )}
        </div>
      )}
      <div className="bulletmeter-barroot" style={{ height }}>
        <div className={clsx("bulletmeter-highvalue")} />
        <div
          className={clsx("bulletmeter-midvalue")}
          style={{ width: `${midValue}%` }}
        />
        {/* Hide the target at 0, since it looks weird to have a 0 target. We can show it if it's confusing. */}
        {target > 0 && (
          <TooltipTrigger isOpen={targetTooltipOpen}>
            <div
              className={clsx("bulletmeter-target", targetColor)}
              style={{ left: `${target}%` }}
              {...targetHoverableProps}
            />
            {targetTooltipEl}
          </TooltipTrigger>
        )}
        <MeasurementBar
          measurement={measurement}
          measurementText={measurementText}
          measurementTooltip={measurementTooltip}
          midValue={midValue}
        />
        <div className="bulletmeter-zeroline" />
      </div>
    </div>
  );
}

function MeasurementBar({
  midValue,
  measurement,
  measurementText,
  measurementTooltip,
}) {
  const textRef = React.useRef();
  const { isOpen: tooltipIsOpen, hoverableProps } = useHoverableTrigger({
    disabled: !measurementTooltip,
  });

  if (!(measurement >= 0)) {
    return null;
  }
  // When the measurement goes into the 'high area, the entire chart gets a glow.
  // It also requires much more logic, so handle the simple case directly.
  const bonusMode = measurement > midValue;
  let bar;
  if (!bonusMode) {
    bar = (
      <div
        className={clsx("bulletmeter-measurement", "regular", "capped")}
        style={{ width: `${measurement}%` }}
        {...hoverableProps}
      />
    );
  } else {
    // The full width is the purple bar with the drop shadow.
    // The width up to the midpoint line is the blue bar, but with transparency.
    bar = (
      <>
        <div
          className={clsx("bulletmeter-measurement", "bonus", "capped")}
          style={{ width: `${measurement}%` }}
          {...hoverableProps}
        />
        <div
          className={clsx("bulletmeter-measurement", "regular")}
          style={{
            width: `${midValue}%`,
            opacity: "75%",
          }}
          {...hoverableProps}
        />
      </>
    );
  }
  return (
    <TooltipTrigger isOpen={tooltipIsOpen}>
      {bar}
      <div
        ref={textRef}
        className="bulletmeter-measurement-text"
        style={{ left: `${measurement}%` }}
        {...hoverableProps}
      >
        {measurementText}
      </div>
      <Tooltip placement="left" triggerRef={textRef} isOpen={tooltipIsOpen}>
        {measurementTooltip}
      </Tooltip>
    </TooltipTrigger>
  );
}
