import { Container } from "@mantine/core";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import api from "../api";
import EmptyState from "../components/EmptyState.jsx";
import LayoutV2 from "../components/LayoutV2/LayoutV2";
import AnimatedStatefulList from "../components/uikit/animate/AnimatedStatefulList.jsx";
import Button from "../components/uikit/Button";
import Card, { CardBody, CardText, CardTitle } from "../components/uikit/Card";
import Stack from "../components/uikit/Stack";
import useFormSubmitter from "../state/useFormSubmitter.js";
import useToast from "../state/useToast.jsx";
import useUser from "../state/useUser.jsx";

export default function InvitationsPage() {
  const { user, setUser } = useUser();
  return (
    <LayoutV2 key={user.id}>
      <Container maw={400} pt="xl">
        <h3 className="mb-3">Invitations</h3>
        {isEmpty(user.invitedOrganizations) ? (
          <EmptyState
            className="mt-5"
            message="You are not invited to any organizations."
            gotoDashboard
          />
        ) : (
          <>
            <p className="subtitle mb-4">
              You are invited to the following organizations:
            </p>
            <Stack>
              <AnimatedStatefulList
                items={user.invitedOrganizations}
                getItemKey={(o) => o.id}
                exitDuration={0.2}
                onItemRemoved={(st) => setUser(st)}
                renderItem={(item, { removeFromList }) => (
                  <Invitation
                    {...item}
                    onAccept={(newUser) => removeFromList(newUser)}
                  />
                )}
              />
            </Stack>
          </>
        )}
      </Container>
    </LayoutV2>
  );
}

function Invitation({ id, name, roleName, invitedAt, onAccept }) {
  const { setUser } = useUser();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { prepareSubmit, handleSubmitError, turnOffScreenLoader } =
    useFormSubmitter();

  function handleAccept(e) {
    prepareSubmit(e);
    api
      .joinOrganization({ id })
      .then((r) => {
        showToast({ title: "Invitation accepted!", variant: "success" });
        if (r.data.invitedOrganizations.length === 0) {
          // If there are no more invitations, send them to the dashboard.
          setUser(r.data);
          navigate("/dashboard");
          return;
        }
        // If there are more invitations, we need to remove the item visually, then update state.
        onAccept(r.data);
        turnOffScreenLoader();
      })
      .catch(handleSubmitError);
  }
  return (
    <Card className="text-center mb-4">
      <CardBody>
        <CardTitle>{name}</CardTitle>
        <CardText>
          Invited as {roleName} at {dayjs(invitedAt).format("lll")}
        </CardText>
        <Button className="mt-2" onClick={handleAccept}>
          Accept
        </Button>
      </CardBody>
    </Card>
  );
}
