import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import "./ResourceRowCard.css";
import Card from "./uikit/Card";
import Indicator from "./uikit/Indicator";
import { FlexiLink } from "./uikit/links";
import Stack from "./uikit/Stack";

/**
 * @typedef ResourceRowCard
 */

/**
 * @param {string} href
 * @param {string} title
 * @param {string|JSX.Element|*} subtitle
 * @param {Icon} icon
 * @param {Array<string>} indicatorNames
 * @param {string} className
 * @param children
 * @param implOnLayout See ResourceRowList.
 * @param implForcedWidth See ResourceRowList.
 */
export default function ResourceRowCard({
  href,
  icon,
  indicatorNames,
  title,
  subtitle,
  className,
  children,
  implOnLayout,
  implForcedWidth,
}) {
  const detailsAreaRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (implOnLayout && implForcedWidth === 0 && detailsAreaRef.current) {
      implOnLayout({ detailsAreaWidth: detailsAreaRef.current.clientWidth });
    }
  }, [implForcedWidth, implOnLayout]);
  return (
    <Card className={clsx("p-3", className)}>
      <Stack row className="justify-start align-start">
        <Stack row className="align-center">
          <FlexiLink href={href} absProps={{ target: "_blank" }}>
            <FontAwesomeIcon icon={icon} className="resource-row-card-icon" />
          </FlexiLink>
          <div
            className="resource-row-card-details"
            ref={detailsAreaRef}
            style={{ width: implForcedWidth ? implForcedWidth + 8 : undefined }}
          >
            <div>
              <FlexiLink
                className="subtitle-strong mr-2 decoration-none"
                href={href}
                absProps={{ target: "_blank" }}
              >
                {title}
              </FlexiLink>
              <Stack row gap={2} className="d-inline-flex">
                {(indicatorNames || []).map(
                  (n) => n && <Indicator key={n} name={n} />,
                )}
              </Stack>
            </div>
            <div className="text-desc color-grey">{subtitle}</div>
          </div>
        </Stack>
        <div className="resource-row-card-spacer" />
        <div
          className="resource-row-card-content"
          style={{ marginTop: "-20px" }}
        >
          {children}
        </div>
      </Stack>
    </Card>
  );
}
