import { AppShell } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import React from "react";
import HeaderV2 from "./HeaderV2";
import SideNavV2 from "./SideNavV2";

export type LayoutV2Props = { children: React.ReactNode };

export default function LayoutV2({
  children,
}: LayoutV2Props): React.JSX.Element {
  const pinned = useHeadroom({ fixedAt: 120 });

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned }}
      navbar={{ width: 300, breakpoint: 1 }}
      c="gray.8"
    >
      <HeaderV2 />
      <SideNavV2 />
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}
