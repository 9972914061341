import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../api";
import { useState } from "react";
import TextField from "../components/uikit/TextField.jsx";
import AuthLayout from "./auth/AuthLayout.jsx";
import useFormSubmitter from "../state/useFormSubmitter.js";

export default function RegisterPage() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { prepareSubmit, handleSubmitError } = useFormSubmitter();
  const invitation = locationState?.invitation;
  const [state, setState] = useState({
    name: invitation?.name || "",
    email: invitation?.email || "",
    password: "",
  });

  function handleSubmit(e) {
    prepareSubmit(e);
    api
      .register(state)
      .then(() => navigate("/account-created"))
      .catch(handleSubmitError);
  }

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  return (
    <AuthLayout
      heading={
        invitation ? (
          <span>
            {invitation.organizationName} invited you
            <br />
            to sign up for Skyway.
          </span>
        ) : (
          "Create your Skyway Account."
        )
      }
      formButtonProps={{ submit: "Create Account" }}
      footerLink={<Link to="/">Or, sign in to an existing account.</Link>}
      onSubmit={handleSubmit}
    >
      <TextField
        name="name"
        value={state.name}
        label="Name"
        wide
        type="name"
        placeholder="Name"
        autoComplete="name"
        onChange={handleChange}
      />
      <TextField
        name="email"
        value={state.email}
        label="Email address"
        wide
        marginTop
        type="email"
        placeholder="Email"
        autoComplete="email"
        readOnly={Boolean(invitation)}
        onChange={handleChange}
      />
      <TextField
        name="password"
        value={state.password}
        label="Password"
        wide
        marginTop
        type="password"
        placeholder="Password"
        autoComplete="new-password"
        onChange={handleChange}
      />
    </AuthLayout>
  );
}
