import { useParams } from "react-router-dom";
import CommitmentDetailPage from "./CommitmentDetailPage";

export default function SpendCommitmentDetailPage() {
  const params = useParams();
  const commitmentId = Number(params.id);
  return (
    <CommitmentDetailPage
      commitmentType="contract_spend_commitments"
      commitmentId={commitmentId}
    />
  );
}
