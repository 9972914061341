import {
  faDownload,
  faFileContract,
  faGear,
  faHome,
  faWallet,
} from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import dayjs from "dayjs";
import random from "lodash/random";
import range from "lodash/range";
import repeat from "lodash/repeat";
import React from "react";
import { DialogTrigger, TooltipTrigger } from "react-aria-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmptyState from "../components/EmptyState.jsx";
import ErrorScreen from "../components/ErrorScreen";
import RelatedList from "../components/RelatedList.jsx";
import ResourceRowCard from "../components/ResourceRowCard";
import ResourceRowList from "../components/ResourceRowList.jsx";
import TopNav from "../components/TopNav.jsx";
import { faArrowsRotateSolid } from "../components/icons.jsx";
import Alert from "../components/uikit/Alert.jsx";
import Button from "../components/uikit/Button";
import Card, { CardBody, CardText, CardTitle } from "../components/uikit/Card";
import Dropdown, { DropdownItem } from "../components/uikit/Dropdown.jsx";
import FileInput from "../components/uikit/FileInput.jsx";
import Filter from "../components/uikit/Filter.jsx";
import Form from "../components/uikit/Form.jsx";
import FormButtons from "../components/uikit/FormButtons.jsx";
import GoBackBreadcumb from "../components/uikit/GoBackBreadcrumb.jsx";
import HoverableTooltipTrigger from "../components/uikit/HoverableTooltipTrigger.jsx";
import Hr from "../components/uikit/Hr";
import Indicator from "../components/uikit/Indicator";
import InlineSelectField from "../components/uikit/InlineSelectField.jsx";
import RadioGroup from "../components/uikit/RadioGroup.jsx";
import ScreenLoader from "../components/uikit/ScreenLoader.tsx";
import SelectField from "../components/uikit/SelectField.jsx";
import SidebarNav from "../components/uikit/SidebarNav.jsx";
import Stack from "../components/uikit/Stack";
import Tabs from "../components/uikit/Tabs.jsx";
import TextField from "../components/uikit/TextField.jsx";
import Tooltip from "../components/uikit/Tooltip.jsx";
import BulletBarChart from "../components/uikit/charts/BulletBarChart.tsx";
import BulletChart from "../components/uikit/charts/BulletChart.tsx";
import BulletMeterChart from "../components/uikit/charts/BulletMeterChart.jsx";
import ConsumptionProgressPill from "../components/uikit/charts/ConsumptionProgressPill.jsx";
import ConsumptionTimelineChart from "../components/uikit/charts/ConsumptionTimelineChart.jsx";
import GanttChart from "../components/uikit/charts/GanttChart.jsx";
import SimpleBulletChart from "../components/uikit/charts/SimpleBulletChart.jsx";
import ConfirmationModal from "../components/uikit/modals/ConfirmationModal.jsx";
import InformationModal from "../components/uikit/modals/InformationModal.jsx";
import useHoverableTrigger from "../components/uikit/useHoverableTrigger.jsx";
import useErrorToast from "../state/useErrorToast.jsx";
import useScreenLoader from "../state/useScreenLoader.jsx";
import useToast from "../state/useToast.jsx";
import useUser from "../state/useUser.jsx";

export default function StyleguidePage() {
  const params = useParams();
  const section = params.section || "";
  const { user, setUser } = useUser();
  React.useEffect(() => {
    if (!user) {
      const fakeuser = fakeusers[Date.now() % fakeusers.length];
      setUser(fakeuser);
    }
  }, [setUser, user]);

  const sections = [
    "typography",
    "buttons",
    "dropdowns",
    "forms",
    "alerts",
    "modals",
    "tooltips",
    "indicators",
    "graphing",
    "topnav",
    "sidenav",
    "tabnav",
    "loaders",
    "errorscreen",
    "emptystate",
    "cards",
    "relatedlist",
    "resourcerow",
  ];

  if (!user) {
    return "User is being set";
  }

  return (
    <div>
      {section === "topnav" && <TopNavDemo />}
      {(section === "sidenav" || section === "sidenav2") && <SideNavDemo />}
      <div className="p-2">
        <div className="mb-2 flex row flex-wrap">
          <Link className="mr-1" to="/">
            Home
          </Link>
          {sections.map((section) => (
            <Link
              key={section}
              to={`/styleguide/${section}`}
              className="text-capitalize mr-1"
            >
              {section}
            </Link>
          ))}
        </div>
        {(!section || section === "typography") && <Typography />}
        {section === "buttons" && <Buttons />}
        {section === "dropdowns" && <Dropdowns />}
        {section === "forms" && <Forms />}
        {section === "alerts" && <Alerts />}
        {section === "modals" && <Modals />}
        {section === "tooltips" && <Tooltips />}
        {section === "indicators" && <Indicators />}
        {section === "graphing" && <Graphing />}
        {section === "loaders" && <Loaders />}
        {section === "errorscreen" && <ErrorScreen />}
        {section === "emptystate" && <EmptyStateDemo />}
        {section === "cards" && <Cards />}
        {section === "relatedlist" && <RelatedLists />}
        {section === "resourcerow" && <ResourceRows />}
        {section.startsWith("tabnav") && <TabsDemo />}
      </div>
    </div>
  );
}

function TopNavDemo() {
  return <TopNav forceReadAll />;
}

function Typography() {
  return (
    <div className="flex column">
      <h1>Heading 1</h1>
      <Spacer />
      <p className="h2">Heading 2</p>
      <Spacer />
      <h3>Heading 3</h3>
      <Spacer />
      <h4>Heading 4</h4>
      <Spacer />
      <h5>Heading 5</h5>
      <Spacer />
      <h6>Heading 6</h6>
      <Spacer />
      <p>Text</p>
      <Spacer />
      <p>
        <strong>Text Bold</strong>
      </p>
      <Spacer />
      <p className="subtitle">Subtitle</p>
      <Spacer />
      <p className="subtitle">
        <strong>Subtitle Bold</strong>
      </p>
      <Spacer />
      <p className="btn-label">Button label</p>
      <Spacer />
      <p className="btn-label-sm">Small button label</p>
      <Spacer />
      <p className="desc">Description</p>
      <Spacer />
      <p className="desc-sm">Small description</p>
    </div>
  );
}

function Buttons() {
  const variants = [
    "primary",
    "secondary",
    "hollow",
    "free",
    "light",
    "hollow-inverted",
    "free-inverted",
    "danger",
    "info",
  ];
  const sizes = ["lg", "md", "sm"];
  const bgs = ["background", "light-grey", "blurple"];
  return (
    <div className="flex row">
      {bgs.map((bg) => (
        <div
          key={bg}
          className={clsx("flex column px-2", `bg-${bg}`)}
          style={{ maxWidth: 200 }}
        >
          {variants.map((variant) => (
            <div key={variant} className="flex column">
              <h5 className="text-capitalize">{variant}</h5>
              {sizes.map((size) => (
                <div key={`${variant}-${size}`} className="flex column">
                  <Button
                    className="my-2"
                    variant={variant}
                    size={size}
                    leftArrow
                    rightArrow
                  >
                    Button
                  </Button>
                  <Button
                    className="my-2"
                    variant={variant}
                    size={size}
                    rightArrow
                    disabled
                  >
                    Button
                  </Button>
                </div>
              ))}
            </div>
          ))}
          <h5 className="text-capitalize">Misc</h5>
          <Button className="my-2">No Arrows</Button>
          <Button className="my-2" href="/should-see-this" size="lg">
            Link
          </Button>
          <Button className="my-2" href="/should-see-this" size="md" disabled>
            Disabled Link
          </Button>
          <Button className="my-2" href="/should-see-this" size="sm">
            Link
          </Button>
        </div>
      ))}
      <Stack gap={2} column className={clsx("px-2")} style={{ maxWidth: 200 }}>
        <h5 className="text-capitalize">Links</h5>
        {variants.map((v) => (
          <React.Fragment key={v}>
            <Button href="#" variant={v} className="text-capitalize">
              {v} Link
            </Button>
            <Button href="#" variant={v} disabled className="text-capitalize">
              {v} Disabled
            </Button>
          </React.Fragment>
        ))}
      </Stack>
      <Stack gap={2} column className={clsx("px-2")} style={{ maxWidth: 200 }}>
        <h5 className="text-capitalize">Icons</h5>
        <Button
          className="my-2"
          variant="primary"
          size="lg"
          leftArrow={faDownload}
          rightArrow={faStar}
        >
          Button
        </Button>
        <Button
          className="my-2"
          variant="primary"
          size="md"
          rightArrow={faDownload}
        >
          Button
        </Button>
        <Button
          className="my-2"
          variant="secondary"
          size="sm"
          leftArrow={faDownload}
        >
          Button
        </Button>
      </Stack>
    </div>
  );
}

function Dropdowns() {
  const [inlineVal, setInlineVal] = React.useState("");
  const items = [
    { value: "v1", label: "Value 1" },
    { value: "v2", label: "Value 2" },
    { value: "v3", label: "Value 3" },
    { value: "v4", label: "Value 4" },
    { value: "v5", label: "Value 5" },
  ];
  return (
    <Stack gap={4} style={{ maxWidth: 500 }}>
      <Dropdown items={items} aria-label="dropdown" />
      <Dropdown items={items} aria-label="dropdown" value="v3" />
      <Dropdown items={items} aria-label="dropdown" />
      <Dropdown
        aria-label="dropdown"
        items={[
          ...items,
          { value: "v6", label: "And here is a much longer label" },
        ]}
      />
      <Dropdown
        aria-label="dropdown"
        value="long"
        items={[
          {
            value: "long",
            label: "This label has overflow so see how the select hides",
          },
        ]}
        buttonProps={{ style: { width: 150 } }}
      />
      <SelectField
        items={items}
        label="Form Dropdown"
        extra="Supports TextField stuff"
      />
      <InlineSelectField
        items={items}
        label="Inline Select"
        value={inlineVal}
        onChange={(c) => setInlineVal(c)}
      />
      <div className="bg-light-grey p-3 gap-3 flex column">
        <SelectField items={items} label="Variant=white" variant="white" />
        <Filter
          label="Example Filter"
          queryParamKey="tablefilter1"
          defaultValue="all"
          width={200}
        >
          <DropdownItem value="all">All</DropdownItem>
          <DropdownItem value="expired">Expired</DropdownItem>
          <DropdownItem value="longvalue">
            Long label to see how this is handled
          </DropdownItem>
        </Filter>
        <Filter label="Other" queryParamKey="tablefilter2" width={100}>
          <DropdownItem value="v1">V1</DropdownItem>
          <DropdownItem value="v2">V2</DropdownItem>
        </Filter>
      </div>
    </Stack>
  );
}

function Forms() {
  return (
    <Form className="p-3">
      <TextField
        className="w-100"
        label="Label"
        extra="Additional note or link"
        value="Input"
        onChange={() => console.log("changed via shorthand")}
      />
      <TextField
        marginTop
        label="Label"
        extra="Everything is good!"
        feedback="success"
        inputProps={{
          value: "Successful",
          onChange: () => console.log("changed via shorthand"),
        }}
      />
      <TextField
        className="w-100"
        marginTop
        label="Label"
        extra="Something is wrong here"
        feedback="error"
        inputProps={{ value: "Oh no", onChange: () => null }}
      />
      <TextField
        marginTop
        label="Tooltip"
        tooltip={
          <span>
            Tooltips are anything{" "}
            <strong>
              <em>renderable</em>
            </strong>{" "}
            even <a href="#">with links</a>. The tooltip can be long but the max
            width is set.
          </span>
        }
      />
      <TextField
        marginTop
        label="Tooltip"
        infoModal={
          <span>
            Using infoModal renders this affordance which shows the value of
            infoModal in an info modal. It can be <strong>any element</strong>{" "}
            or string.
          </span>
        }
      />
      <TextField
        marginTop
        label="Some other field"
        wide
        extra="This is a long extra line, which should fit."
      />
      <TextField
        marginTop
        label="Label"
        extra="Additional note or link"
        disabled
        inputProps={{ value: "Disabled Input", onChange: () => null }}
      />
      <TextField
        marginTop
        label="Label"
        extra="Additional note or link"
        readOnly
        inputProps={{ value: "readOnly Input", onChange: () => null }}
      />
      <Stack col gap={3}>
        <RadioGroup
          label="Unlabeled group"
          labelHidden
          items={[
            { label: "Soccer", value: "soccer" },
            { label: "Basketball", value: "basketball" },
          ]}
        />
        <RadioGroup
          value="soccer"
          label="Radio Field group"
          items={[
            { label: "Soccer", value: "soccer" },
            { label: "Basketball", value: "basketball" },
          ]}
        />
        <FileInput />
        <FileInput
          files={[
            { name: "file1abcdefHIJK" },
            { name: "file2abcdefHIJK" },
            { name: "file3abcdefg" },
          ]}
        />
      </Stack>
      <FormButtons />
      <FormButtons wide />
      <FormButtons successMessage="Set successMessage to show a happy alert." />
      <FormButtons errorMessage="Set errorMessage to show an error alert." />
      <FormButtons infoMessage="Set infoMessage to show an informational alert." />
    </Form>
  );
}

function Alerts() {
  const { showErrorToast } = useErrorToast();
  const { showToast } = useToast();
  const makeMsg = () =>
    repeat("This is a message of variable length. ", random(1, 6));
  return (
    <Stack gap={3}>
      <Alert
        icon
        title="This uses defaults"
        primary
        secondary
        onPrimary={logarg}
        onSecondary={logarg}
      ></Alert>
      <Alert className="my-2" title="Only dismissable" dismissable></Alert>
      <Alert
        icon={faGear}
        title="The title identifies what happened"
        subtitle="The body content lets the user know why, and how to remedy or proceed."
        primary="Confirm"
        secondary="Deny"
        dismissable
        onClose={logarg}
        onPrimary={logarg}
        onSecondary={logarg}
      ></Alert>
      <Button onClick={() => showErrorToast(makeMsg())}>Error Toast</Button>
      <Button
        onClick={() =>
          showToast({
            message: makeMsg(),
            variant: "success",
            duration: 1000000,
          })
        }
      >
        Success Toast
      </Button>
      <Button
        onClick={() =>
          showToast({
            message: makeMsg(),
            variant: "info",
            dimissable: false,
          })
        }
      >
        Info Toast
      </Button>
      <Button
        onClick={() => showToast({ title: makeMsg(), variant: "warning" })}
      >
        Warning Toast
      </Button>
      <Button
        onClick={() =>
          showToast({
            title: makeMsg(),
            message: makeMsg(),
            icon: false,
            variant: "warning",
          })
        }
      >
        Warning Toast (No Icon)
      </Button>
      <Button onClick={() => showToast({ title: makeMsg(), icon: faWallet })}>
        Info Toast (Custom Icon)
      </Button>
      <Button
        onClick={() => showToast({ message: "See me go!", duration: 400 })}
      >
        Quick duration
      </Button>
      <Alert
        variant="success"
        title="This is a message title"
        subtitle="This is a message subtitle"
        dismissable
      />
      <Alert
        variant="info"
        title="Here is a title"
        subtitle="Informational message"
        dismissable
      />
      <Alert variant="warning" title="Title only" dismissable />
      <Alert variant="error" subtitle="Subtitle only" dismissable />
      <Alert variant="error">
        This uses children instead of explicit title/subtitle.
      </Alert>
    </Stack>
  );
}

function Modals() {
  const [isOpen1, setOpen1] = React.useState(false);
  const [isOpen2, setOpen2] = React.useState(false);
  const [isOpen3, setOpen3] = React.useState(false);
  const [isOpen4, setOpen4] = React.useState(false);
  return (
    <Stack gap={3} col style={{ maxWidth: 300 }}>
      <Button onClick={() => setOpen1(true)}>Confirmation Modal</Button>
      <ConfirmationModal
        isOpen={isOpen1}
        heading="Ask for yes no."
        confirm="Yes, do it"
        reject="No, do not"
        onConfirm={() => {
          setOpen1(false);
          console.log("Confirmation confirmed");
        }}
        onReject={() => {
          setOpen1(false);
          console.warn("Confirmation rejected");
        }}
      >
        More details about the choice
      </ConfirmationModal>

      <Button onClick={() => setOpen2(true)}>Accept Only</Button>
      <ConfirmationModal
        isOpen={isOpen2}
        heading="Do the thing?"
        reject={false}
        onConfirm={() => setOpen2(false)}
        onReject={() => setOpen2(false)}
      />

      <Button onClick={() => setOpen3(true)}>Info Modal</Button>
      <InformationModal isOpen={isOpen3} onOpenChange={setOpen3}>
        <p>Here is info about what is going on</p>
      </InformationModal>

      <Button onClick={() => setOpen4(true)}>Info Modal with buttons</Button>
      <InformationModal
        heading="About that..."
        isOpen={isOpen4}
        onOpenChange={setOpen4}
        closeButton={<Button>Right...</Button>}
      >
        <p>Here is info about what is going on</p>
      </InformationModal>

      <DialogTrigger>
        <Button>Using Dialog Trigger</Button>
        <InformationModal closeButton>
          The close button still works.
        </InformationModal>
      </DialogTrigger>
    </Stack>
  );
}

function Tooltips() {
  const variants = ["dark", "light"];
  const directions = ["top", "right", "bottom", "left"];
  const div1Ref = React.useRef();
  const { hoverableProps, isOpen: sharedIsOpen } = useHoverableTrigger();
  return (
    <Stack
      col
      gap={3}
      style={{ maxWidth: 150, marginLeft: 200, marginTop: 50 }}
    >
      {variants.map((v) => (
        <React.Fragment key={v}>
          {directions.map((d) => (
            <TooltipTrigger key={d} delay={0}>
              <Button>
                {v}-{d}
              </Button>
              <Tooltip placement={d} variant={v}>
                {d} tooltip here
              </Tooltip>
            </TooltipTrigger>
          ))}
        </React.Fragment>
      ))}
      <HoverableTooltipTrigger>
        <div>Hover me</div>
        <Tooltip>I am a normal tooltip</Tooltip>
      </HoverableTooltipTrigger>
      <HoverableTooltipTrigger>
        <div>Hover me (right)</div>
        <Tooltip placement="right">I am a normal tooltip</Tooltip>
      </HoverableTooltipTrigger>
      <HoverableTooltipTrigger>
        <div>Hover me (bottom)</div>
        <Tooltip placement="bottom">I am a normal tooltip</Tooltip>
      </HoverableTooltipTrigger>
      <TooltipTrigger isOpen={sharedIsOpen}>
        <div ref={div1Ref} {...hoverableProps}>
          I anchor a tooltip
        </div>
        <div {...hoverableProps}>
          But hovering me shows the tooltip above too
        </div>
        <Tooltip placement="right" triggerRef={div1Ref} isOpen={sharedIsOpen}>
          I am shared between these divs
        </Tooltip>
      </TooltipTrigger>
    </Stack>
  );
}

function Indicators() {
  const names = [
    "under-target",
    "on-target",
    "over-target",
    "active",
    "expired",
    "future",
    "importing",
    "new",
    "valid",
    "invalid",
    "member",
    "admin",
    "invited",
    "trial",
    "invoice",
    "saas",
    "???",
  ];
  return (
    <div className="flex column">
      {names.map((n) => (
        <div key={n} className="my-2">
          <Indicator name={n} />
        </div>
      ))}
    </div>
  );
}

function Graphing() {
  return (
    <Stack gap={4} className="mb-5">
      <h5>Simple Bullet Chart</h5>
      <SimpleBulletChart shape="linear" />
      <SimpleBulletChart shape="linear" fill="blue" empty="red" complete={75} />
      <SimpleBulletChart shape="linear" complete={0} />
      <SimpleBulletChart color="red" />
      <SimpleBulletChart color="red" complete={60} vertical={{ at: 65 }} />
      <SimpleBulletChart
        color="red"
        complete={60}
        vertical={{ at: 60, icon: faArrowsRotateSolid, title: "Hello" }}
      />
      <h5>Ring Bullet Chart</h5>
      <Stack row gap={4}>
        <SimpleBulletChart shape="ring" ringSize={80} complete={25} />
        <SimpleBulletChart shape="ring" ringSize={80} complete={50} />
        <SimpleBulletChart shape="ring" ringSize={47} complete={75} />
        <SimpleBulletChart
          shape="ring"
          fill="blue"
          empty="red"
          ringSize={100}
          complete={75}
        />
      </Stack>
      <h5>Bullet Chart</h5>
      <Stack row gap={4}>
        <BulletChart measurement={60} lowValue={50} midValue={75} target={80} />
        <BulletChart
          measurement={100}
          lowValue={30}
          midValue={45}
          target={20}
        />
        <BulletChart
          measurement={50}
          lowValue={30}
          midValue={45}
          target={20}
          height={100}
          width={100}
        />
        <BulletChart
          measurement={50}
          lowValue={30}
          midValue={45}
          target={20}
          height={200}
          width={20}
        />
        <BulletChart measurement={50} />
        <BulletChart lowValue={30} midValue={45} target={60} />
        <BulletChart lowValue={30} />
        <BulletChart midValue={45} target={60} />
        <BulletChart midValue={45} target={60} focusMarker />
        <BulletChart target={0} />
        <BulletChart lowValue={20} midValue={45} target={60} variant="grey" />
      </Stack>
      <h5>Bullet Bar Chart</h5>
      <BulletBarChart
        height={200}
        width="100%"
        barWidth={24}
        yAxis={[
          { label: "0" },
          { label: "5k" },
          { label: "10k" },
          { label: "15k" },
          { label: "20.5k" },
        ]}
        items={[
          {
            label: "Jan",
            measurement: 10,
            target: 15,
            lowValue: 5,
            midValue: 25,
          },
          {
            label: "February 2023",
            measurement: 20,
            target: 25,
            lowValue: 15,
            midValue: 35,
          },
          {
            label: "Mar",
            measurement: 50,
            target: 75,
            lowValue: 25,
            midValue: 50,
            focusMarker: true,
          },
          {
            label: "Apr",
            target: 0,
            lowValue: 35,
            midValue: 55,
          },
          {
            label: "May",
            target: 45,
            lowValue: 35,
            midValue: 55,
            muted: true,
          },
        ]}
      />
      <BulletBarChart
        height={150}
        width="100%"
        barWidth={24}
        yAxis={[
          { label: "0" },
          { label: "5k" },
          { label: "10k" },
          { label: "15k" },
          { label: "20k" },
        ]}
        items={range(0, 48).map((i) => ({
          label: `${i}`,
          label2: i % 10 === 0 ? "YEARX" : "",
          measurement: i < 33 ? 50 : 0,
          target: 50,
          lowValue: 25,
          midValue: 75,
          focusMarker: i === 33,
          muted: i > 33,
        }))}
      />
      <h5>Consumption Progress</h5>
      <ConsumptionTimelineChart
        period={{ lower: "2022-01-01", upper: "2022-12-31", elapsed: 0.5 }}
        progress={{
          currentValueFormatted: "$22,000,000",
          maxValueFormatted: "$43,000,000",
          valueRatio: 0.6,
          currentValue: 22000000,
          tracking: "on_target",
        }}
      />
      <ConsumptionTimelineChart
        period={{ lower: "2022-01-01", upper: "2022-12-31", elapsed: 0 }}
        progress={{
          currentValueFormatted: "0 GB-mo",
          maxValueFormatted: "1024 GB-mo",
          valueRatio: 0,
          currentValue: 0,
          tracking: "on_target",
        }}
      />
      <ConsumptionTimelineChart
        period={{ lower: "2022-01-01", upper: "2022-12-31", elapsed: 0.2 }}
        progress={{
          currentValueFormatted: "100 GB-mo",
          maxValueFormatted: "1024 GB-mo",
          valueRatio: 0.2,
          currentValue: 100,
          tracking: "under_target",
        }}
      />
      <ConsumptionTimelineChart
        period={{ lower: "2022-01-01", upper: "2022-12-31", elapsed: 0.7 }}
        progress={{
          currentValueFormatted: "2000 GB-mo",
          maxValueFormatted: "1024 GB-mo",
          valueRatio: 2,
          currentValue: 2000,
          tracking: "over_target",
        }}
      />
      <h5>Bullet Meter</h5>
      <BulletMeterChart
        measurement={50}
        measurementText="7.15 PB"
        lowValue={25}
        midValue={75}
        target={70}
        targetTracking="under_target"
      />
      <BulletMeterChart
        measurement={73}
        measurementText="8.25 PB"
        measurementTooltip={
          <span>
            This is <strong>how much you have used</strong>.
          </span>
        }
        lowValue={25}
        midValue={80}
        midValueText="9.05 PB"
        midValueTooltip={
          <span>
            Use this and get a <strong>discount</strong>.
          </span>
        }
        target={70}
        targetText="8.0 PB"
        targetTooltip={
          <span>
            This is <strong>how much you</strong> need to use by the checkin.
          </span>
        }
        targetTracking="on_target"
      />
      <BulletMeterChart
        measurement={90}
        measurementText="10.25 PB"
        lowValue={25}
        midValue={75}
        target={70}
        targetTracking="over_target"
      />
      <h5>Progress Pills</h5>
      <Stack row gap={3}>
        <ConsumptionProgressPill
          period={{ upper: "2022-12-31", current: true }}
          width={120}
          progress={{
            maxValueFormatted: "99.99 PB",
            valueRatio: 0.4,
            tracking: "on_target",
          }}
          tooltip={
            <span>
              here is a <strong>tooltip</strong>
            </span>
          }
        />
        <ConsumptionProgressPill
          period={{ upper: "2022-12-31", future: true }}
          width={80}
          progress={{
            maxValueFormatted: "99.99 PB",
            valueRatio: 0,
          }}
        />
        <ConsumptionProgressPill
          period={{ upper: "2022-12-31", past: true }}
          width={80}
          progress={{
            maxValueFormatted: "99.99 PB",
            valueRatio: 1,
            tracking: "on_target",
          }}
        />
        <ConsumptionProgressPill
          period={{ upper: "2022-12-31", past: true }}
          width={80}
          progress={{
            maxValueFormatted: "99.99 PB",
            valueRatio: 0.8,
            tracking: "under_target",
          }}
        />
        <ConsumptionProgressPill
          period={{ upper: "2022-12-31", past: true }}
          width={80}
          progress={{
            maxValueFormatted: "99.99 PB",
            valueRatio: 1.5,
            tracking: "over_target",
          }}
        />
      </Stack>
      <h5>Gantt Chart</h5>
      <GanttChart
        today={dayjs("2023-08-15")}
        start={dayjs("2023-01-01")}
        monthsForward={12}
        items={[
          {
            key: "1",
            start: dayjs("2022-06-01"),
            end: dayjs("2023-04-30"),
            title: "Starts before graph",
            suffix: "Ends 04/30/2023",
            icon: faFileContract,
          },
          {
            key: "2",
            start: dayjs("2023-09-01"),
            end: dayjs("2024-03-15"),
            title: "Ends after graph",
            suffix: "Begins 09/01/2023",
            icon: faFileContract,
            markers: [
              {
                key: "offchart",
                at: dayjs("2023-12-15"),
                title: "See me 12/15/2023",
                icon: faStar,
              },
              {
                key: "offchart",
                at: dayjs("2024-01-15"),
                title: "Should not see me",
                icon: faStar,
              },
            ],
          },
          {
            key: "3",
            start: dayjs("2023-03-01"),
            end: dayjs("2023-10-15"),
            title: "Contained by graph",
            suffix: "Ends 10/15/2023",
            icon: faFileContract,
          },
          {
            key: "4",
            start: dayjs("2023-01-01"),
            end: dayjs("2023-12-31"),
            title: "Full Year at graph boundary (w/link)",
            suffix: "Begins 01/01/2023",
            icon: faFileContract,
            href: "#",
            markers: [
              {
                key: "overlapstart",
                at: dayjs("2023-03-15"),
                title: "Overlap start 3/15/2023",
                icon: faArrowsRotateSolid,
              },
              {
                key: "middle",
                at: dayjs("2023-06-15"),
                title: "In the middle 6/15/2023",
                icon: faArrowsRotateSolid,
              },
              {
                key: "overlapend",
                at: dayjs("2023-11-15"),
                title: "Overlap end 11/15/2023",
                icon: faArrowsRotateSolid,
              },
            ],
          },
          {
            key: "1b",
            start: dayjs("2022-11-15"),
            end: dayjs("2023-04-30"),
            title: "Same end, diff start",
            suffix: "Ends 04/30/2023",
            icon: faFileContract,
          },
          {
            key: "2b",
            start: dayjs("2023-09-01"),
            end: dayjs("2024-11-10"),
            title: "Same start, diff end",
            suffix: "Begins 09/01/2023",
            icon: faFileContract,
          },
          {
            key: "lowerinf",
            start: dayjs(null),
            end: dayjs("2023-06-10"),
            title: "Lower infinity",
            icon: faFileContract,
          },
          {
            key: "upperinf",
            start: dayjs("2023-06-10"),
            end: dayjs(null),
            title: "Upper infinity",
            icon: faFileContract,
          },
          {
            key: "inf",
            start: dayjs(null),
            end: dayjs(null),
            title: "Infinity",
            icon: faFileContract,
          },
        ]}
      />
    </Stack>
  );
}

function TabsDemo() {
  const navigate = useNavigate();
  let { section } = useParams();
  section = section || "tabnav-1";
  return (
    <div className="flex column">
      <Tabs
        items={[
          { label: "Tab 1", href: "/styleguide/tabnav-1" },
          { label: "Tab 2", href: "/styleguide/tabnav-2" },
        ]}
        selectedKey={`/styleguide/${section}`}
        onSelectionChange={navigate}
      />
      <p>selected: {section}</p>
      <Spacer />
      <GoBackBreadcumb>List of Stuff</GoBackBreadcumb>
    </div>
  );
}

function SideNavDemo() {
  return (
    <div className="flex column">
      <TopNavDemo />
      <div className="flex row" style={{ height: 500 }}>
        <SidebarNav
          items={[
            { label: "Href 1", icon: faHome, href: "/" },
            { label: "Href 2", icon: faHome, href: "/styleguide" },
            { label: "Href 3", icon: faHome, href: "/styleguide#sidenav" },
            { label: "Disabled", icon: faWallet, href: "", disabled: true },
          ]}
        />
      </div>
    </div>
  );
}

function Loaders() {
  const screenLoader = useScreenLoader();
  function handleScreenLoader() {
    screenLoader.turnOn();
    window.setTimeout(screenLoader.turnOff, 2000);
  }
  return (
    <Stack gap={3}>
      <Button onClick={handleScreenLoader}>Show Screen Loader (2s)</Button>
      <Button onClick={screenLoader.turnOn}>Show Screen Loader (∞)</Button>
      <ScreenLoader scrim={false} height={300} />
    </Stack>
  );
}

function EmptyStateDemo() {
  return (
    <Stack gap={4} className="mt-4">
      <hr className="w-100" />
      <EmptyState>Empty state with no button.</EmptyState>
      <hr className="w-100" />
      <EmptyState gotoDashboard>Empty state with button.</EmptyState>
      <hr className="w-100" />
    </Stack>
  );
}

function Cards() {
  const variants = ["transparent-white", "dashed"];
  return (
    <Stack gap={4} className="flex-wrap bg-light-grey p-5">
      <Card style={{ width: 300 }}>
        <CardBody>
          <CardTitle>This is a title</CardTitle>
          <CardText>This is text</CardText>
        </CardBody>
      </Card>
      <Card style={{ width: 300 }}>
        <div>Do not use CardBody and you do not get the padding.</div>
      </Card>
      {variants.map((v) => (
        <Card key={v} variant={v} style={{ width: 300 }}>
          <CardBody>
            <CardTitle>variant={v}</CardTitle>
          </CardBody>
        </Card>
      ))}
    </Stack>
  );
}
function RelatedLists() {
  return (
    <Stack col className="mt-3" gap={3} style={{ maxWidth: 500 }}>
      <RelatedList
        title="List 1"
        subtitle="subtitle1"
        viewAllText="View All Things"
        viewAllHref="/"
      >
        <div>Item 1</div>
        <div>Item 2</div>
        <div>Item 3</div>
      </RelatedList>
      <Hr />
      <RelatedList
        title="Loading List"
        subtitle="shows the loading state"
        viewAllText="View All Things"
        viewAllHref="/"
        loading
      />
      <Hr />
      <RelatedList
        title="Error List"
        subtitle="shows the error state"
        viewAllText="View All Things"
        viewAllHref="/"
        error
      />
    </Stack>
  );
}

function ResourceRows() {
  const commonKid = (
    <div className="bg-light-grey">
      the right area fills the minimum width across all rows
    </div>
  );

  return (
    <Stack col className="bg-light-grey p-3" gap={3}>
      <ResourceRowCard
        href="#"
        icon={faGear}
        title="Resource Name"
        subtitle={
          <span>
            <a href="#">Contract</a> expires in 123 days
          </span>
        }
      >
        <div className="bg-light-grey">
          this fills the right area, is often a graph
        </div>
      </ResourceRowCard>
      <ResourceRowCard
        href="#"
        icon={faGear}
        title="Resource Name"
        indicatorNames={["under-target", "expired", "invalid"]}
      />
      <h5>Resource Row List (even spacing of right area)</h5>
      <ResourceRowList>
        <ResourceRowCard
          href="#"
          icon={faGear}
          title="Resource Name"
          indicatorNames={[]}
        >
          {commonKid}
        </ResourceRowCard>
        <ResourceRowCard
          href="#"
          icon={faGear}
          title="Resource Name"
          indicatorNames={["invalid"]}
        >
          {commonKid}
        </ResourceRowCard>
        <ResourceRowCard
          href="#"
          icon={faGear}
          title="Resource Name"
          indicatorNames={["under-target", "over-target"]}
        >
          {commonKid}
        </ResourceRowCard>
      </ResourceRowList>
    </Stack>
  );
}

function Spacer() {
  return <div className="my-3" />;
}

function logarg(e) {
  console.log(e);
}

const dbg = {
  id: 1,
  name: "Duckbill Group",
  key: "dbg",
};
const whdb = {
  id: 2,
  name: "WebhookDB",
  key: "whdb",
};
const fakeusers = [
  {
    name: "Mike Julian",
    email: "mike@duckbillgroup.com",
    activeOrganization: { ...dbg, active: true },
    availableOrganizations: [{ ...dbg, active: true }],
    invitedOrganizations: [whdb],
    avatarSrc:
      "https://robohash.org/f552d0d46472eb5d301a9c1170c5edc3?set=set1&bgset=bg1&size=200x200",
  },
  {
    name: "Robert Galanakis",
    email: "rob@lithic.tech",
    activeOrganization: { ...whdb, active: true },
    availableOrganizations: [dbg, { ...whdb, active: true }],
    invitedOrganizations: [],
    avatarSrc:
      "https://robohash.org/b11136d13fa1c8635cdb860faf65e8de?set=set1&bgset=bg1&size=200x200",
  },
];
