import React, { useCallback, useMemo } from "react";
import badContext from "../modules/badContext";
import { localStorageCache } from "../modules/localStorageHelper";

const DEFAULT_COLOR_MODE = "light";

export const GlobalViewStateContext = React.createContext({
  navElement: null,
  setNavElement: badContext("GlobalViewState"),
  colorMode: DEFAULT_COLOR_MODE,
  setColorMode: badContext("GlobalViewState"),
});

export default function GlobalViewStateProvider({ children }) {
  const [navElement, setNavElement] = React.useState(null);
  const [colorMode, setColorModeInner] = localStorageCache.useState(
    "color-mode",
    DEFAULT_COLOR_MODE,
  );
  const setColorMode = useCallback(
    (v) => {
      if (!v) {
        v = invertColorMode(colorMode);
      }
      document.querySelector("html").setAttribute("data-bs-theme", v);
      setColorModeInner(v);
    },
    [colorMode, setColorModeInner],
  );
  const invertedColorMode = invertColorMode(colorMode);
  const value = useMemo(
    () => ({
      navElement,
      setNavElement,
      colorMode,
      setColorMode,
      invertedColorMode,
    }),
    [navElement, colorMode, setColorMode, invertedColorMode],
  );
  return (
    <GlobalViewStateContext.Provider value={value}>
      {children}
    </GlobalViewStateContext.Provider>
  );
}

function invertColorMode(colorMode) {
  return colorMode === "light" ? "dark" : "light";
}
