export default function Money({ children, format }) {
  const fmt = formats[format] || formats.whole;
  const m = fmt.format(children);
  return <span>{m}</span>;
}

const usd = (o) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...o,
  });

const formats = {
  cents: usd(),
  whole: usd({ minimumFractionDigits: 0 }),
};
